import Checked from "../assets/icon-checked.svg";
import Unchecked from "../assets/icon-unchecked.svg";
import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../firebase";
import React, {Dispatch, SetStateAction, useState} from "react";
import "../styles/mobile.css";

type Props = {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
}

const MobileInput = ({checked, setChecked, setOpen, setSubmitted}: Props) => {
  const [name, setName] = useState<string>("");
  const [phoneNum, setPhoneNum] = useState<string>("");
  const [male, setMale] = useState<boolean>(true);
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!name || !phoneNum) {
      alert("성함과 전화번호를 모두 입력해주세요");
    }
    try {
      const applyCollection = collection(db, "apply");
      const q = query(applyCollection, where("phone_num", "==", phoneNum));
      const existing = await getDocs(q);

      if (!existing.empty) {
        alert("이미 등록된 연락처입니다.");
        return;
      }

      await addDoc(applyCollection, {
        name: name,
        phone_num: phoneNum,
        checked: false,
        isMale: male,
        createdAt: Date.now()
      });
      if (typeof window !== "undefined" && typeof window.fbq === "function") {
        window.fbq("track", "CompleteRegistration");
        console.log("CompleteRegistration");
      } else {
        console.warn("Facebook Pixel이 아직 로드되지 않았습니다.");
      }
      setSubmitted(true);
      setName("");
      setPhoneNum("");
    } catch (error) {
      console.error("요청 중 오류 발생: ", error);
    }
  }

  function formatPhoneNumber(text: string): string {
    const isSeoulNZ = text.slice(0, 2) === "02";
    const nationZoneRgex = isSeoulNZ ? "(\\d{1,2})" : "(\\d{1,3})";
    const phonePattern = new RegExp(`^${nationZoneRgex}(\\d{0,4})(\\d{0,4}).*$`);

    return text
      .replace(/[^0-9]/g, "")
      .replace(phonePattern, "$1-$2-$3")
      .replace(/^-/, "")
      .replace(/-$/, "")
      .replace(/-$/, "");
  }

  return (
    <form className={'m-tenth-content'} onSubmit={handleSubmit} autoComplete="off">
      <div className={'m-landing-gender'}>
        <div className={`m-landing-gender-option ${male && 'selected'}`}
             onClick={() => setMale(true)}>남성
        </div>
        <div className={`m-landing-gender-option ${!male && 'selected'}`}
             onClick={() => setMale(false)}>여성
        </div>
      </div>
      <div className={'m-input-box'}>
        <div className={'input-label text-20 opacity-60'}>성함</div>
        <input className={'m-input'}
               type={"text"}
               value={name}
               onChange={(e) => setName(e.target.value)}
               maxLength={8}
               placeholder={"성함을 입력해주세요."}
               required/>
      </div>
      <div className={'m-input-box'}>
        <div className={'input-label text-20 opacity-60'}>연락처</div>
        <input className={'m-input'}
               type={"text"}
               value={formatPhoneNumber(phoneNum)}
               onChange={(e) => setPhoneNum(e.target.value)}
               placeholder={"숫자만 입력해주세요. ex)01012345678"}
               required/>
      </div>
      <div className={'m-warning-text'} onClick={() => setChecked(!checked)}>
        <img className={'checkbox'} src={checked ? Checked : Unchecked} alt={''}/>
        <div className={'policy-text'}>
                <span className={'a'} onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                }}>개인정보 수집 및 이용</span>에 동의합니다
        </div>
      </div>
      <button className={'m-submit'} type={"submit"} disabled={!checked}>보내기</button>
    </form>
  )
}

export default MobileInput;