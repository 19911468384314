import "../styles/modal.css";
import {Dispatch, SetStateAction} from "react";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  isMobile?: boolean;
}
const SubmitModal = ({setOpen, isMobile = false}: Props) => {

  return (
    <div className={'modal-bg'}
         onClick={(e) => {
           e.preventDefault();
           setOpen(false);
         }}>
      <div className={'modal'} onClick={(e) => e.stopPropagation()}>
        <div className={'modal-title'}>제출이 완료되었습니다</div>
        <div className={'modal-desc'} style={{opacity: 1, textAlign: 'center'}}>
          하단 <span style={{color: 'var(--primary)'}}>플래너 연결</span>을 통해 빠른 상담 받아보세요.
        </div>
        <div className={'modal-buttons'} style={{marginTop: '12px'}}>
          <div className={'cancel'} onClick={() => setOpen(false)}>닫기</div>
          <div className={'confirm'}
               onClick={() => {
                 window.open("http://pf.kakao.com/_CxhXqn", "_blank")
               }}>플래너 연결
          </div>
        </div>
        <div className={'confirm-desc'}>
          카카오톡 번호 저장이 제한된 경우,
          플래너 연결 버튼을 통해 채팅 문의를 남겨주시기 바랍니다.
        </div>
      </div>
    </div>
  )
}

export default SubmitModal;