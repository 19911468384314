import Nice from "../assets/nice.png";
import Cx from "../assets/cx.png";
import "../styles/scroll.css";

const Scroll = () => {

  return (
    <div className={'scroll'}>
      <div className={'m-third-content'}>
        <div className={'m-diff-box'}>
          <div className={'m-diff-box-top'}>
            <div className={'text-20'}>철저한 본인 인증</div>
            <div className={'text-14 opacity-60'}>
              본인 명의 휴대폰의 <span className={'bold'}>NICE 신용평가 인증</span>으로만 가입이 가능하며 <span
              className={'bold'}>1인 1개의 아이디만</span>을
              소유할 수 있습니다.
              유령회원/알바회원 또한 존재 할 수 없게 운영할것을 서약합니다.
            </div>
          </div>
          <div className={'m-diff-box-btn'}>
            <img className={'m-diff-box-icon'} src={Nice} alt={''}/>
            NICE 신용평가
          </div>
        </div>
        <div className={'m-diff-box diff-box-linear'}>
          <div className={'m-diff-box-top'}>
            <div className={'text-20'}>운영 방침 준수</div>
            <div className={'text-14 opacity-60'}>이용규칙을 위반하거나 비매너적인 행위 적발시 경고, 혹은 탈퇴 조치가 이루어질 수 있음을 안내드립니다.&nbsp;
              <span className={'bold'}>어나더라운지 CX팀은 24시간 동안 지속적으로 모니터링</span>하여 건전한 서비스 이용을 위해 최선을 다하겠습니다
            </div>
          </div>
          <div className={'m-diff-box-btn'}>
            <img className={'m-diff-box-icon'} src={Cx} alt={''}/>
            ANOTHER CX TEAM
          </div>
        </div>
        <div className={'m-diff-box last'}>
          <div className={'text-20'}>다양한 서비스</div>
          <div className={'text-14 opacity-60'}>
            1. 데일리카드를 통해 매일 전달되는 <br/>&nbsp;&nbsp;&nbsp; 매력적인 이성 카드 <br/>
            2. 가입비없이 플래너를 통한 나만의<br/>
            &nbsp;&nbsp;&nbsp; 1:1 매칭 서비스 <br/>
            3. 다양한 주제를 바탕으로 토론하는 <br/>&nbsp;&nbsp;&nbsp; 커뮤니티 매칭 서비스 <br/>
            4. 회원이 직접 선택 하는 회원가입<br/>&nbsp;&nbsp;&nbsp; 심사
          </div>
        </div>
      </div>
    </div>
  )
}

export default Scroll;