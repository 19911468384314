// import React, { useEffect, useState } from "react";
// import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import { collection, addDoc, getDocs, where, query, deleteDoc, doc } from "firebase/firestore";
// import { db } from "../firebase";
//
// type Props = {
//   open: boolean;
//   onClose: () => void;
//   userId: string | null; // 특정 사용자 ID
// };
//
// type Photo = {
//   id: string;
//   image: string;
//   createdAt: number;
// };
//
// const PhotoModal: React.FC<Props> = ({ open, onClose, userId }) => {
//   const [photos, setPhotos] = useState<Photo[]>([]);
//
//   useEffect(() => {
//     if (open) {
//       fetchPhotos();
//     }
//   }, [open]);
//
//   const fetchPhotos = async () => {
//     if (!userId) return;
//     try {
//       const photoCollection = collection(db, "user_photos");
//       const q = query(photoCollection, where("userId", "==", userId));
//       const querySnapshot = await getDocs(q);
//
//       let fetchedPhotos: Photo[] = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       })) as Photo[];
//
//       fetchedPhotos = fetchedPhotos.sort((a, b) => b.createdAt - a.createdAt);
//       setPhotos(fetchedPhotos);
//     } catch (error) {
//       console.error("사진 불러오기 실패:", error);
//     }
//   };
//
//   const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (!file || !userId) return;
//
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onloadend = () => {
//       const base64String = reader.result as string;
//       saveImageToFirestore(base64String);
//     };
//   };
//
//   const saveImageToFirestore = async (base64String: string) => {
//     if (!userId) return;
//     try {
//       const newPhoto = {
//         userId,
//         image: base64String,
//         createdAt: Date.now(),
//       };
//       const docRef = await addDoc(collection(db, "user_photos"), newPhoto);
//
//       setPhotos([{ id: docRef.id, ...newPhoto }, ...photos]);
//     } catch (error) {
//       console.error("사진 업로드 실패:", error);
//     }
//   };
//
//   const handleDeletePhoto = async (id: string) => {
//     if (!window.confirm("정말 삭제하시겠습니까?")) return;
//
//     try {
//       await deleteDoc(doc(db, "user_photos", id));
//       setPhotos((prev) => prev.filter((photo) => photo.id !== id));
//     } catch (error) {
//       console.error("사진 삭제 실패:", error);
//     }
//   };
//
//   return (
//     <Modal open={open} onClose={onClose}>
//       <Box sx={styles.modal}>
//         <Box sx={styles.header}>
//           <Typography variant="h6" color={"black"}>회원 사진</Typography>
//           <IconButton onClick={onClose}>
//             <CloseIcon />
//           </IconButton>
//         </Box>
//
//         {/* 사진 업로드 버튼 */}
//         <Button component="label" variant="contained" fullWidth sx={{ mb: 2, background: "#FFA000" }}>
//           사진 업로드
//           <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
//         </Button>
//
//         {/* 업로드된 사진 리스트 */}
//         <Box sx={styles.photoList}>
//           {photos.length === 0 ? (
//             <Typography variant="body2" color="gray">
//               업로드된 사진이 없습니다.
//             </Typography>
//           ) : (
//             photos.map((photo) => (
//               <Box key={photo.id} sx={styles.photoItem}>
//                 <img src={photo.image} alt="업로드된 사진" style={{ width: "100%", borderRadius: "8px" }} />
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   size="small"
//                   fullWidth
//                   onClick={() => handleDeletePhoto(photo.id)}
//                 >
//                   삭제
//                 </Button>
//               </Box>
//             ))
//           )}
//         </Box>
//       </Box>
//     </Modal>
//   );
// };
//
// // ✅ 스타일 정의
// const styles = {
//   modal: {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 400,
//     bgcolor: "background.paper",
//     boxShadow: 24,
//     p: 3,
//     borderRadius: "8px",
//   },
//   header: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     mb: 2,
//   },
//   photoList: {
//     maxHeight: "300px",
//     overflowY: "auto",
//     display: "flex",
//     flexDirection: "column",
//     gap: "12px",
//   },
//   photoItem: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     gap: "8px",
//   },
// };
//
// export default PhotoModal;

import React, { useEffect, useState } from "react";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { collection, addDoc, getDocs, where, query, deleteDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { db, storage } from "../firebase"; // ✅ Firebase Storage 추가
import { v4 as uuidv4 } from "uuid"; // ✅ 파일명 중복 방지

type Props = {
  open: boolean;
  onClose: () => void;
  userId: string | null;
};

type Photo = {
  id: string;
  imageUrl: string;
  createdAt: number;
  storagePath: string; // ✅ Storage 내부 파일 경로 저장
};

const PhotoModal: React.FC<Props> = ({ open, onClose, userId }) => {
  const [photos, setPhotos] = useState<Photo[]>([]);

  useEffect(() => {
    if (open) {
      fetchPhotos();
    }
  }, [open]);

  const fetchPhotos = async () => {
    if (!userId) return;
    try {
      const photoCollection = collection(db, "user_photos");
      const q = query(photoCollection, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      let fetchedPhotos: Photo[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Photo[];

      fetchedPhotos = fetchedPhotos.sort((a, b) => b.createdAt - a.createdAt);
      setPhotos(fetchedPhotos);
    } catch (error) {
      console.error("사진 불러오기 실패:", error);
    }
  };

  // const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (!file || !userId) return;
  //
  //   try {
  //     // ✅ 고유한 파일명 생성
  //     const uniqueFileName = `${userId}/${uuidv4()}.${file.name.split('.').pop()}`;
  //     const storageRef = ref(storage, `user_photos/${uniqueFileName}`);
  //
  //     // ✅ Firebase Storage에 이미지 업로드
  //     await uploadBytes(storageRef, file);
  //
  //     // ✅ 다운로드 URL 가져오기
  //     const downloadURL = await getDownloadURL(storageRef);
  //
  //     // ✅ Firestore에 이미지 정보 저장 (Storage 경로도 함께 저장)
  //     const newPhoto = {
  //       userId,
  //       imageUrl: downloadURL,
  //       createdAt: Date.now(),
  //       storagePath: storageRef.fullPath, // Storage 경로 저장
  //     };
  //     const docRef = await addDoc(collection(db, "user_photos"), newPhoto);
  //
  //     setPhotos([{ id: docRef.id, ...newPhoto }, ...photos]);
  //   } catch (error) {
  //     console.error("사진 업로드 실패:", error);
  //   }
  // };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file || !userId) return;

    try {
      // 고유한 파일명 생성
      const uniqueFileName = `${userId}/${uuidv4()}.${file.name.split('.').pop()}`;
      const storageRef = ref(storage, `user_photos/${uniqueFileName}`);

      // Firebase Storage에 이미지 업로드
      await uploadBytes(storageRef, file);

      // 다운로드 URL 가져오기
      const downloadURL = await getDownloadURL(storageRef);

      // Firestore에 이미지 정보 저장
      const newPhoto = {
        userId,
        imageUrl: downloadURL,
        createdAt: Date.now(),
        storagePath: storageRef.fullPath, // Storage 경로 저장
      };
      const docRef = await addDoc(collection(db, "user_photos"), newPhoto);

      setPhotos([{ id: docRef.id, ...newPhoto }, ...photos]);
    } catch (error) {
      console.error("사진 업로드 실패:", error);
    }
  };



  const handleDeletePhoto = async (id: string, storagePath: string) => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return;

    try {
      // ✅ Firebase Storage에서 삭제
      const storageRef = ref(storage, storagePath);
      await deleteObject(storageRef);

      // ✅ Firestore에서 삭제
      await deleteDoc(doc(db, "user_photos", id));

      setPhotos((prev) => prev.filter((photo) => photo.id !== id));
    } catch (error) {
      console.error("사진 삭제 실패:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modal}>
        <Box sx={styles.header}>
          <Typography variant="h6" color={"black"}>회원 사진</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* 사진 업로드 버튼 */}
        <Button component="label" variant="contained" fullWidth sx={{ mb: 2, background: "#FFA000" }}>
          사진 업로드
          <input type="file" hidden onChange={handleImageUpload} accept="image/*" />
        </Button>

        {/* 업로드된 사진 리스트 */}
        <Box sx={styles.photoList}>
          {photos.length === 0 ? (
            <Typography variant="body2" color="gray">
              업로드된 사진이 없습니다.
            </Typography>
          ) : (
            photos.map((photo) => (
              <Box key={photo.id} sx={styles.photoItem}>
                <img src={photo.imageUrl} alt="업로드된 사진" style={{ width: "100%", borderRadius: "8px" }} />
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  fullWidth
                  onClick={() => handleDeletePhoto(photo.id, photo.storagePath)}
                >
                  삭제
                </Button>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Modal>
  );
};

// ✅ 스타일 정의
const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "8px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 2,
  },
  photoList: {
    maxHeight: "300px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  photoItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
};

export default PhotoModal;
