import {styled} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";

export const StyledGrid = styled(DataGrid)(() => ({
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(24px)',
    width: '100%',
    maxWidth: '1400',
    maxHeight: '800px',
    fontSize: '14px',
    fontFamily: 'Pretendard, sans-serif',
    fontWeight: 500,

  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 'bold',
    fontSize: '16px',
  },

  "& .MuiDataGrid-columnHeaderTitle": {
    textTransform: 'uppercase', // 텍스트 대문자 변환 (옵션)
  },

  "& .checked": {
    color: "#AAAAAA",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
}));
