import "../styles/web.css";
import Navigation from "./Navigation";
import Bg from "../assets/background.png";
import PlayStore from "../assets/icon-google-play.png";
import AppStore from "../assets/icon-app-store.png";
import Nice from "../assets/nice.png";
import Cx from "../assets/cx.png";
import DownloadBtn from "./DownloadBtn";
import Screen from "../assets/screen.png";
import React, {useEffect, useRef, useState} from "react";
import Tags from "../assets/scroll-web.png";
import DailyCard from "../assets/daily-card.png";
import LovePlanner from "../assets/love-planner.png";
import Community from "../assets/community.png";
import Review from "../assets/review.png";
import Logo from "../assets/logo.svg";
import GooglePlayFull from "../assets/google-play-full.png";
import AppStoreFull from "../assets/app-store-full.png";
import QR from "../assets/qr-code.png";
import Footer from "./Footer";
import {db} from "../firebase";
import {collection, addDoc, query, where, getDocs} from "firebase/firestore";
import Info1 from "../assets/info1.png";
import Info2 from "../assets/info2.png";
import Info3 from "../assets/info3.png";
import Planner from "../assets/planner.png";
import PageBox from "./PageBox";
import Matching from "./Matching";
import Card from "./Card";
import useScrollFadeIn from "../utils/useScrollFadeIn";
import {useLocation, useNavigate} from "react-router-dom";
import Checked from "../assets/icon-checked.svg";
import Unchecked from "../assets/icon-unchecked.svg";
import Modal from "./Modal";
import Floating from "./Floating";
import {formatPhoneNumber} from "../utils/formatPhoneNumber";
import SubmitModal from "./SubmitModal";


const Landing = () => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [phoneNum, setPhoneNum] = useState<string>("");
  const location = useLocation();
  const bottomRef = useRef<HTMLDivElement>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [fChecked, setFChecked] = useState<boolean>(false);
  const [fOpen, setFOpen] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [male, setMale] = useState<boolean>(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!name || !phoneNum) {
      alert("성함과 전화번호를 모두 입력해주세요");
    }
    try {
      const applyCollection = collection(db, "apply");
      const q = query(applyCollection, where("phone_num", "==", phoneNum));
      const existing = await getDocs(q);

      if (!existing.empty) {
        alert("이미 등록된 연락처입니다.");
        return;
      }

      await addDoc(applyCollection, {
        name: name,
        phone_num: phoneNum,
        isMale: male,
        checked: false,
        createdAt: Date.now()
      });
      if (typeof window !== "undefined" && typeof window.fbq === "function") {
        window.fbq("track", "CompleteRegistration");
        console.log("CompleteRegistration");
      } else {
        console.warn("Facebook Pixel이 아직 로드되지 않았습니다.");
      }
      setSubmitted(true);
      setName("");
      setMale(true);
      setPhoneNum("");
    } catch (error) {
      console.error("요청 중 오류 발생: ", error);
    }
  }

  useEffect(() => {
    if (location.state?.scrollToBottom) {
      bottomRef.current?.scrollIntoView();
    }
  }, [location]);

  return (
    <div className={'body'}>
      <Navigation/>
      <img className={'background'} src={Bg} alt={''}/>
      <div className={'another-class-content'}>
        <div className={'first'}>
          <div className={'title-box'} {...useScrollFadeIn("up", 1, 0)} >
            <div className={'title-1'}>나의 가치를 더 빛내줄 인연</div>
            <div className={'title-2'}>남다른 사람들의 데이팅앱</div>
            <div className={'title-3 glowing'}>어나더 라운지</div>
          </div>
          <div className={'download-buttons'}>
            <DownloadBtn icon={PlayStore} url={''}/>
            <DownloadBtn icon={AppStore} url={''}/>
          </div>
        </div>
        <div className={'second'} {...useScrollFadeIn("up", 1, 0.5)} >
          <div className={'text-36'}>
            <span style={{color: 'var(--primary)'}}>자격</span>있는 남녀의&nbsp;
            <span style={{color: 'var(--primary)'}}>품격</span> 있는 소개팅
          </div>
          <div className={'second-content'}>
            <div className={'text-20'}>능력 있는 당신, 특별한 만남에 한 발짝 다가오세요</div>
            <div className={'text-20'} style={{opacity: '.6', marginTop: '12px', textAlign: 'center'}}>외모와 능력 대한민국 상위
              10%에 포함하는 회원님들을
              모시고,<br/>
              프라이빗한 1:1 프리미엄 매칭을 진행합니다.
            </div>
          </div>
        </div>
        <div className={'third'} {...useScrollFadeIn("up", 1, 0)} >
          <div className={'text-36'}>
            철저한 <span style={{color: 'var(--primary)'}}>인증</span> 프로세스
          </div>
          <div className={'text-20'} style={{textAlign: 'center'}}>
            8가지 항목과 20개 이상의 세부 기준으로 구성된 신뢰 기반 인증 서류 <br/>
            어나더 라운지는 믿을만한 인연을 만들어 드립니다.
          </div>
          <div className={'third-content'}>
            <div className={'diff-box'}>
              <div className={'diff-box-top'}>
                <div className={'text-20'}>철저한 본인 인증</div>
                <div className={'text-14 opacity-60'}>
                  본인 명의 휴대폰의 <span className={'bold'}>NICE 신용평가 인증</span>으로만 가입이 가능하며 <span className={'bold'}>1인 1개의 아이디만</span>을
                  소유할 수 있습니다.
                  유령회원/알바회원 또한 존재 할 수 없게 운영할것을 서약합니다.
                </div>
              </div>
              <div className={'diff-box-btn'}>
                <img className={'diff-box-icon'} src={Nice} alt={''}/>
                NICE 신용평가
              </div>
            </div>
            <div className={'diff-box diff-box-linear'}>
              <div className={'diff-box-top'}>
                <div className={'text-20'}>운영 방침 준수</div>
                <div className={'text-14 opacity-60'}>이용규칙을 위반하거나 비매너적인 행위 적발시 경고, 혹은 탈퇴 조치가 이루어질 수 있음을 안내드립니다.&nbsp;
                  <span className={'bold'}>어나더라운지 CX팀은 24시간 동안 지속적으로 모니터링</span>하여 건전한 서비스 이용을 위해 최선을 다하겠습니다
                </div>
              </div>
              <div className={'diff-box-btn'}>
                <img className={'diff-box-icon'} src={Cx} alt={''}/>
                ANOTHER CX TEAM
              </div>
            </div>
            <div className={'diff-box text'}>
              <div className={'text-20'}>다양한 서비스</div>
              <div className={'text-14 opacity-60'}>
                1. 데일리카드를 통해 매일 전달되는 매력적인 이성 카드 <br/>
                2. 가입비없이 플래너를 통한 나만의 1:1 매칭 서비스 <br/>
                3. 다양한 주제를 바탕으로 토론하는 커뮤니티 매칭 서비스 <br/>
                4. 회원이 직접 선택 하는 회원가입 심사
              </div>
            </div>
          </div>
        </div>
        <div className={'fourth'} {...useScrollFadeIn("up", 1, 0)} >
          <div className={'fourth-background'}/>
          <img className={'fourth-content'} src={Screen} alt={''}/>
          <div className={'fourth-mask'}>
            <img className={'fourth-tags'} src={Tags} alt={''}/>
          </div>
        </div>
        <Card/>
        <div className={'sixth'} {...useScrollFadeIn("up", 1.2, 0)} >
          <div className={'text-32'} style={{textAlign: 'center'}}>
            결정사의 무게감은 그대로, <br/>데이팅앱의 편리함이 합쳐진 하이엔드 매칭
          </div>
          <div className={'text-20 opacity-60'}>회원님의 이상형을 토대로 인연을 설계합니다.</div>
          <PageBox/>
          <div className={'text-20'} style={{fontWeight: 600}}>Q. 플래너를 통한 매칭은&nbsp;
            <span style={{color: 'var(--primary)'}}>서비스 범주</span>가 어떻게 되나요?
          </div>
          <div className={'text-20 opacity-60'} style={{textAlign: 'center'}}>
            플래너와의 상담을 통해 이상형과 회원님의 정보를 바탕으로 매칭이 진행됩니다. <br/>
            선택 후 만남의 시간과 장소까지 세부적으로 조율해 드립니다.
          </div>
          <div className={'sixth-content-box-2'}>
            <div className={'sixth-content-2'}>상담 및<br/>니즈 파악</div>
            <div className={'sixth-content-2'}>상호 매칭</div>
            <div className={'sixth-content-2'}>일정 조율 및<br/>장소 협의</div>
          </div>
        </div>
        <div className={'seventh'} {...useScrollFadeIn("up", 1, 0)} >
          <div className={'text-32'}>내 사람 찾기, 이젠 플래너에게 맡겨요.</div>
          <div className={'text-20 opacity-60'} style={{textAlign: 'center'}}>
            원하는 이성의 상세한 디테일과 획득한 뱃지를 플래너에게 요청하세요.
            <br/>합리적이고 효율적인 육각형 소개팅을 할 수 있어요
          </div>
          <div className={'seventh-content-box-1'}>
            <div className={'seventh-content-1-1'}>회원</div>
            <div className={'seventh-content-1-2'}>
              <div className={'info-row'}>
                <img className={'info-row-img'} src={Info1} alt={''}/>
                <div className={'info-row-text'}>안정적인 직업</div>
              </div>
              <div className={'info-row'}>
                <img className={'info-row-img'} src={Info2} alt={''}/>
                <div className={'info-row-text'}>경제적인 능력</div>
              </div>
              <div className={'info-row'} style={{marginTop: '20px'}}>
                <img className={'info-row-img'} src={Info3} alt={''}/>
                <div className={'info-row-text'}>매력적인 외모</div>
              </div>
            </div>
            <img className={'planner'} src={Planner} alt={''}/>
          </div>
          <Matching isMobile={false}/>
        </div>
        <div className={'eighth'}>
          <div className={'eighth-content'} {...useScrollFadeIn("right", 1, 0)} >
            <div className={'text-28'}>Daily Card</div>
            <div className={'eighth-text text-14 opacity-60'}>
              까다로운 심사를 통과한 회원분들을 소개합니다. 수만명의 이성중 나에게 도달한 오늘의 인연을 확인하세요.
            </div>
            <img className={'eighth-img'} src={DailyCard} alt={''}/>
          </div>
          <div className={'eighth-content'} {...useScrollFadeIn("right", 1, 0.5)} >
            <div className={'text-28'}>Love Planner</div>
            <div className={'eighth-text text-14 opacity-60'}>
              나만의 플래너와 함께 취향에 딱 맞는 이상형을 만나보세요.
            </div>
            <img className={'eighth-img'} src={LovePlanner} alt={''}/>
          </div>
          <div className={'eighth-content'} {...useScrollFadeIn("right", 1, 1)} >
            <div className={'text-28'}>Community</div>
            <div className={'eighth-text text-14 opacity-60'}>
              호감의 시작은 데일리카드에서, 인연의 시작은 커뮤니티에서 다양한 주제로 이야기하고 서로의 취향과 성격을 알아보세요.
            </div>
            <img className={'eighth-img'} src={Community} alt={''}/>
          </div>
        </div>
        <div className={'ninth'} {...useScrollFadeIn("up", 1, 0)} >
          <div className={'text-32'} style={{textAlign: 'center'}}>새로운 인연, 특별한 순간. <br/>
            당신의 이야기도 곧 여기서 시작될 수 있습니다.
          </div>
          <div className={'text-20 opacity-60'} style={{textAlign: 'center'}}>
            2018년 부터 이어온<br/>어나더 라운지에서 시작된 회원님들의 특별한 후기 입니다.
          </div>
          <img className={'review'} src={Review} alt={''}
               style={{width: '100%', maxWidth: '100%'}}/>
        </div>
        <div className={'bottomRef'} ref={bottomRef}>&nbsp;</div>
        <div className={'tenth'} {...useScrollFadeIn("up", 1, 0.4)}>
          <div className={'text-36'}>소개요청</div>
          <div className={'tenth-title'} style={{textAlign: 'center'}}>
            어나더라운지를 체험해보세요.
          </div>
          <div className={'text-14 opacity-60'}>앱 내 사용 가능한 최대 20만원 상당의 재화를 제공해드립니다.</div>
          <form className={'tenth-content'} onSubmit={handleSubmit} autoComplete="off">
            <div className={'landing-gender'}>
              <div className={`landing-gender-option ${male && 'selected'}`}
                   onClick={() => setMale(true)}>남성
              </div>
              <div className={`landing-gender-option ${!male && 'selected'}`}
                   onClick={() => setMale(false)}>여성
              </div>
            </div>
            <div className={'input-box'}>
              <div className={'input-label text-20 opacity-60'}>성함</div>
              <input className={'input'}
                     type={"text"}
                     value={name}
                     onChange={(e) => setName(e.target.value)}
                     maxLength={8}
                     placeholder={"성함을 입력해주세요."}
                     required/>
            </div>
            <div className={'input-box'}>
              <div className={'input-label text-20 opacity-60'}>연락처</div>
              <input className={'input'}
                     type={"text"}
                     value={formatPhoneNumber(phoneNum)}
                     onChange={(e) => setPhoneNum(e.target.value)}
                     placeholder={"숫자만 입력해주세요. ex)01012345678"}
                     required
              />
            </div>
            <div className={'tenth-text-box'}>
              <div className={'warning-text'} onClick={() => setChecked(!checked)}>
                <img className={'checkbox'} src={checked ? Checked : Unchecked} alt={''}/>
                어나더라운지의<span className={'a'} onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}> 개인정보 수집 및 이용</span>에 동의합니다
              </div>
              <button className={'submit'} type="submit" disabled={!checked}>제출</button>
            </div>
          </form>
        </div>
        <div className={'download'} {...useScrollFadeIn("up", 1, 0)} >
          <img className={'download-logo'} src={Logo} alt={''}/>
          <div className={'text-28'}>앱 다운로드 <br/><br/> 공식 런칭 2025.03</div>
          <div className={'download-buttons'}>
            <div className={'download-button'} onClick={() => navigate("/early-trial")}>
              <img className={'download-img'} src={GooglePlayFull} alt={''}/>
            </div>
            <div className={'download-button'} onClick={() => navigate("/early-trial")}>
              <img className={'download-img'} src={AppStoreFull} alt={''}/>
            </div>
          </div>
          <img className={'qr-code'} src={QR} alt={''}/>
        </div>
      </div>
      <Footer/>
      {open && (
        <Modal setOpen={setOpen} setChecked={setChecked}/>
      )}
      {fOpen && (
        <Modal setOpen={setFOpen} setChecked={setFChecked}/>
      )}
      {submitted && (
        <SubmitModal setOpen={setSubmitted} />
      )}
      <Floating fChecked={fChecked} setFChecked={setFChecked} setFOpen={setFOpen} setSubmitted={setSubmitted}/>
    </div>
  )
}

export default Landing;