// import "../styles/admin-page.css";
// import {useEffect, useState} from "react";
// import {
//   collection,
//   getDocs,
//   query,
//   orderBy,
//   doc,
//   updateDoc,
//   deleteDoc, where,
// } from "firebase/firestore";
// import {db} from "../firebase";
// import {GridColDef, GridRowClassNameParams} from "@mui/x-data-grid";
// import {ToggleOff, ToggleOn} from "@mui/icons-material";
// import {Box, MenuItem, Select, SelectChangeEvent} from "@mui/material";
// import {StyledGrid} from "../styles/styled-grid";
// import {useAdmin} from "../utils/AdminContext";
// import MemberInfoModal from "./MemberInfoModal";
// import IdealInfoModal from "./IdealInfoModal";
// import MatchHistoryModal from "./MatchHistoryModal";
// import ProfileHistoryModal from "./ProfileHistoryModal";
//
// type ApplyItem = {
//   id: string;
//   name: string;
//   phoneNum: string;
//   isMale: boolean | undefined;
//   checked: boolean;
//   createdAt: number;
// }
//
// const AdminPage = () => {
//   const {isAdmin, login, logout} = useAdmin();
//   const [applies, setApplies] = useState<ApplyItem[]>([])
//   const [selectedRow, setSelectedRow] = useState<ApplyItem | null>(null);
//   const [paginationModel, setPaginationModel] = useState({
//     page: 0,
//     pageSize: 10,
//   });
//   const [openMemberInfo, setOpenMemberInfo] = useState<boolean>(false);
//   const [openIdealInfo, setOpenIdealInfo] = useState<boolean>(false);
//   const [openMatchHistory, setOpenMatchHistory] = useState<boolean>(false);
//   const [openProfileHistory, setOpenProfileHistory] = useState<boolean>(false);
//   const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
//
//   useEffect(() => {
//     const fetchApplies = async () => {
//       try {
//         const applyCollection = collection(db, "apply");
//         const q = query(applyCollection, orderBy("createdAt", "desc"));
//         const querySnapshot = await getDocs(q);
//         const applyList: ApplyItem[] = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           phoneNum: doc.data().phone_num,
//           ...doc.data(),
//         })) as ApplyItem[];
//         setApplies(applyList);
//       } catch (error) {
//         console.error("데이터 가져오기 실패:", error);
//       }
//     };
//
//     fetchApplies();
//   }, []);
//
//   const toggleChecked = async (id: string, currentChecked: boolean) => {
//     try {
//       const applyDoc = doc(db, "apply", id);
//       await updateDoc(applyDoc, {checked: !currentChecked});
//
//       setApplies((prev) =>
//         prev.map((row) =>
//           row.id === id ? {...row, checked: !currentChecked} : row
//         )
//       );
//
//       if (selectedRow && selectedRow.id === id) {
//         setSelectedRow((prev) => (prev ? {...prev, checked: !currentChecked} : null));
//       }
//     } catch (error) {
//       console.error("확인 여부 변경 중 오류 발생:", error);
//     }
//   };
//
//   const handleOpenModal = (type: string, userId: string) => {
//     setSelectedUserId(userId);
//     if (type === '회원 정보') {
//       setOpenMemberInfo(true);
//     } else if (type === "이상형 정보") {
//       setOpenIdealInfo(true);
//     } else if (type === '매칭 이력') {
//       setOpenMatchHistory(true);
//     } else if (type === '프로필') {
//       setOpenProfileHistory(true)
//     }
//   };
//
//   const handleDelete = async (id: string) => {
//     try {
//       await deleteDoc(doc(db, "apply", id));
//
//       // 이상형정보 삭제
//       const notesCollection = collection(db, "ideal_notes");
//       const q1 = query(notesCollection, where("userId", "==", id));
//
//       const querySnapshot1 = await getDocs(q1);
//
//       const deletePromises1 = querySnapshot1.docs.map((doc) => deleteDoc(doc.ref));
//       await Promise.all(deletePromises1);
//
//       // 회원정보 삭제
//       const memberCollection = collection(db, "member_notes");
//       const q2 = query(memberCollection, where("userId", "==", id));
//
//       const querySnapshot2 = await getDocs(q2);
//
//       const deletePromises2 = querySnapshot2.docs.map((doc) => deleteDoc(doc.ref));
//       await Promise.all(deletePromises2);
//
//       // 매칭 이력 삭제
//       const histoiryCollection = collection(db, "match_history");
//       const q3 = query(histoiryCollection, where("userId", "==", id));
//
//       const querySnapshot3 = await getDocs(q3);
//
//       const deletePromises3 = querySnapshot3.docs.map((doc) => deleteDoc(doc.ref));
//       await Promise.all(deletePromises3);
//
//       // 프로필 이력 삭제
//       const profileCollection = collection(db, "profile_history");
//       const q4 = query(profileCollection, where("userId", "==", id));
//
//       const querySnapshot4 = await getDocs(q4);
//
//       const deletePromises4 = querySnapshot4.docs.map((doc) => deleteDoc(doc.ref));
//       await Promise.all(deletePromises4);
//
//       setApplies((prev) => prev.filter((row) => row.id !== id));
//     } catch (e) {
//       console.error("행 삭제 중 오류 발생", e);
//     }
//   }
//
//   const updateGenderField = async (id: string, value: boolean) => {
//     try {
//       const applyDoc = doc(db, "apply", id);
//       await updateDoc(applyDoc, {["isMale"]: value});
//
//       setApplies((prev) =>
//         prev.map((row) =>
//           row.id === id ? {...row, ["isMale"]: value} : row
//         )
//       );
//     } catch (error) {
//       console.error("필드 업데이트 중 오류 발생:", error);
//     }
//   }
//   const updateApplyField = async (id: string, field: string, value: string | number) => {
//     try {
//       const applyDoc = doc(db, "apply", id);
//       await updateDoc(applyDoc, {[field]: value});
//
//       setApplies((prev) =>
//         prev.map((row) =>
//           row.id === id ? {...row, [field]: value} : row
//         )
//       );
//     } catch (error) {
//       console.error("필드 업데이트 중 오류 발생:", error);
//     }
//   };
//
//   const columns: GridColDef[] = [
//     {field: "id", headerName: "ID", flex: 0.5},
//     {
//       field: "isMale", headerName: "ID", flex: 0.5,
//       renderCell: (params) => (
//         <Select
//           value={params.value === undefined ? "X" : params.value ? "남" : "여"} // X는 표시되지만 선택 불가능
//           onChange={(event: SelectChangeEvent) => {
//             const newValue = event.target.value === "남";
//             updateGenderField(params.row.id,  newValue );
//           }}
//           sx={{  fontSize: "14px" }}
//         >
//           {params.value === undefined && <MenuItem value="X">X</MenuItem>} {/* X는 보여주지만 선택 불가능 */}
//           <MenuItem value="남">남</MenuItem>
//           <MenuItem value="여">여</MenuItem>
//         </Select>
//       ),
//     },
//     {
//       field: "createdAt",
//       headerName: "등록 일자",
//       minWidth: 180,
//       valueFormatter: (value) => {
//         const date = new Date(value as number);
//         return new Intl.DateTimeFormat("ko-KR", {
//           year: "2-digit",
//           month: "2-digit",
//           day: "2-digit",
//           hour: "2-digit",
//           minute: "2-digit",
//           hour12: false,
//         }).format(date).replace(/\./g, "").replace(/ /g, ".");
//       },
//     },
//     {field: "name", headerName: "성함", flex: 1},
//     {field: "phoneNum", headerName: "연락처", flex: 1.5},
//
//     // ✅ 티켓 선택 (드롭다운)
//     {
//       field: "ticket",
//       headerName: "티켓",
//       flex: 1,
//       renderCell: (params) => (
//         <Select
//           value={params.value || "미결제"}
//           onChange={(event: SelectChangeEvent) =>
//             updateApplyField(params.row.id, "ticket", event.target.value)
//           }
//           sx={{width: "100px", fontSize: "14px"}}
//         >
//           <MenuItem value="미결제">미결제</MenuItem>
//           <MenuItem value="class.1">class.1</MenuItem>
//           <MenuItem value="class.2">class.2</MenuItem>
//           <MenuItem value="class.3">class.3</MenuItem>
//         </Select>
//       ),
//     },
//
//     {
//       field: "count",
//       headerName: "횟수",
//       flex: 0.7,
//       renderCell: (params) => (
//         <Select
//           value={params.value || 0}
//           onChange={(event: SelectChangeEvent) =>
//             updateApplyField(params.row.id, "count", event.target.value)
//           }
//           sx={{width: "80px", fontSize: "14px"}}
//         >
//           <MenuItem value={0}>-</MenuItem>
//           <MenuItem value={1}>1회</MenuItem>
//           <MenuItem value={3}>3회</MenuItem>
//           <MenuItem value={5}>5회</MenuItem>
//         </Select>
//       ),
//     },
//     {
//       field: "memberInfo",
//       headerName: "회원 정보",
//       flex: 1,
//       renderCell: (params) => (
//         <button
//           style={{
//             padding: "4px 8px",
//             background: "#323232",
//             color: "white",
//             border: "none",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//           onClick={() => handleOpenModal("회원 정보", params.row.id)}
//         >
//           회원 정보 추가
//         </button>
//       )
//     },
//     {
//       field: "idealInfo",
//       headerName: "이상형 정보",
//       flex: 1,
//       renderCell: (params) => (
//         <button
//           style={{
//             padding: "4px 8px",
//             background: "#656565",
//             color: "white",
//             border: "none",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//           onClick={() => handleOpenModal("이상형 정보", params.row.id)}
//         >
//           이상형 정보 추가
//         </button>
//       )
//     },
//     {
//       field: "match_history",
//       headerName: "매칭 이력",
//       flex: 1,
//       renderCell: (params) => (
//         <button
//           style={{
//             padding: "4px 8px",
//             background: "#8f8f8f",
//             color: "white",
//             border: "none",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//           onClick={() => handleOpenModal("매칭 이력", params.row.id)}
//         >
//           매칭 이력
//         </button>
//       )
//     },
//     {
//       field: "profile_history",
//       headerName: "프로필",
//       flex: 1,
//       renderCell: (params) => (
//         <button
//           style={{
//             padding: "4px 8px",
//             background: "#8f8f8f",
//             color: "white",
//             border: "none",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//           onClick={() => handleOpenModal("프로필", params.row.id)}
//         >
//           프로필
//         </button>
//       )
//     },
//     {
//       field: "checked",
//       headerName: "확인 여부",
//       flex: 0.6,
//       renderCell: (params) => (
//         <span
//           style={{
//             color: params.value ? "#AAAAAA" : "inherit",
//             cursor: "pointer",
//           }}
//           onClick={() => toggleChecked(params.row.id, params.value as boolean)}
//         >
//         {params.value ? (
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               gap: "12px",
//             }}
//           >
//             <ToggleOn sx={{width: 24, height: 24, color: "#83A7DD"}}/> 확인됨
//           </div>
//         ) : (
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               gap: "12px",
//             }}
//           >
//             <ToggleOff sx={{width: 24, height: 24, color: "#CCCCCC"}}/> 미확인
//           </div>
//         )}
//       </span>
//       ),
//     },
//     {
//       field: "delete",
//       headerName: "삭제",
//       flex: 0.5,
//       renderCell: (params) => (
//         <button
//           style={{
//             padding: "4px 8px",
//             background: "#ff4d4f",
//             color: "white",
//             border: "none",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//           onClick={() => handleDelete(params.row.id)}
//         >
//           삭제
//         </button>
//       ),
//     },
//   ];
//   const getRowClassName = (params: GridRowClassNameParams) => {
//     return params.row.checked ? 'checked' : '';
//   };
//
//   return (
//     <div className={'admin'}>
//       <Box sx={{width: '100%', display: "flex", flexDirection: "row", alignItems: "center", gap: '20px'}}>
//         <Box sx={{width: "100%"}}>
//           {!isAdmin ? (
//             <div className="admin-login-content">
//               <div className="admin-description">
//                 어드민 페이지에 접근하려면 <br/> Google 로그인을 해주세요.
//               </div>
//               <button className="admin-button" onClick={login}>
//                 Google 로그인
//               </button>
//             </div>
//           ) : (
//             <div className={'admin-auth'}>
//               <div className={'list'}>
//                 <span>소개 요청 내역</span>
//                 <button onClick={logout} className={'admin-button'}>로그아웃</button>
//               </div>
//               <StyledGrid
//                 rows={applies}
//                 columns={columns}
//                 getRowClassName={getRowClassName}
//                 paginationModel={paginationModel}
//                 onPaginationModelChange={setPaginationModel}
//                 pageSizeOptions={[10, 20, 50]}
//               />
//             </div>
//           )}
//         </Box>
//       </Box>
//       <MemberInfoModal open={openMemberInfo} onClose={() => {
//         setOpenMemberInfo(false)
//         setSelectedUserId(null)
//       }} userId={selectedUserId}/>
//       <IdealInfoModal open={openIdealInfo} onClose={() => {
//         setOpenIdealInfo(false)
//         setSelectedUserId(null)
//       }} userId={selectedUserId}/>
//       <MatchHistoryModal open={openMatchHistory} onClose={() => {
//         setOpenMatchHistory(false)
//         setSelectedUserId(null)
//       }} userId={selectedUserId}/>
//       <ProfileHistoryModal open={openProfileHistory} onClose={() => {
//         setOpenProfileHistory(false)
//         setSelectedUserId(null)
//       }} userId={selectedUserId}/>
//     </div>
//   )
// }
//
// export default AdminPage;
import "../styles/admin-page.css";
import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  updateDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { GridColDef, GridRowClassNameParams } from "@mui/x-data-grid";
import {CameraAlt, ToggleOff, ToggleOn} from "@mui/icons-material";
import {Box, MenuItem, Select, SelectChangeEvent, Tabs, Tab, IconButton} from "@mui/material";
import { StyledGrid } from "../styles/styled-grid";
import { useAdmin } from "../utils/AdminContext";
import MemberInfoModal from "./MemberInfoModal";
import IdealInfoModal from "./IdealInfoModal";
import MatchHistoryModal from "./MatchHistoryModal";
import ProfileHistoryModal from "./ProfileHistoryModal";
import PhotoModal from "./PhotoModal";

type ApplyItem = {
  id: string;
  name: string;
  phoneNum: string;
  isMale: boolean | undefined;
  checked: boolean;
  createdAt: number;
};

const AdminPage = () => {
  const { isAdmin, login, logout } = useAdmin();
  const [applies, setApplies] = useState<ApplyItem[]>([]);
  const [selectedRow, setSelectedRow] = useState<ApplyItem | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [selectedTab, setSelectedTab] = useState<number>(0); // ✅ 탭 상태 추가
  const [openMemberInfo, setOpenMemberInfo] = useState<boolean>(false);
  const [openIdealInfo, setOpenIdealInfo] = useState<boolean>(false);
  const [openMatchHistory, setOpenMatchHistory] = useState<boolean>(false);
  const [openProfileHistory, setOpenProfileHistory] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [openPhotoModal, setOpenPhotoModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchApplies = async () => {
      try {
        const applyCollection = collection(db, "apply");
        const q = query(applyCollection, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        const applyList: ApplyItem[] = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          phoneNum: doc.data().phone_num,
          ...doc.data(),
        })) as ApplyItem[];
        setApplies(applyList);
      } catch (error) {
        console.error("데이터 가져오기 실패:", error);
      }
    };

    fetchApplies();
  }, []);

  // ✅ 선택된 탭에 따라 필터링된 데이터 반환
  const filteredApplies = applies.filter((row) => {
    if (selectedTab === 1) return row.isMale === true; // 남성 회원만
    if (selectedTab === 2) return row.isMale === false; // 여성 회원만
    return true; // 전체
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const toggleChecked = async (id: string, currentChecked: boolean) => {
    try {
      const applyDoc = doc(db, "apply", id);
      await updateDoc(applyDoc, {checked: !currentChecked});

      setApplies((prev) =>
        prev.map((row) =>
          row.id === id ? {...row, checked: !currentChecked} : row
        )
      );

      if (selectedRow && selectedRow.id === id) {
        setSelectedRow((prev) => (prev ? {...prev, checked: !currentChecked} : null));
      }
    } catch (error) {
      console.error("확인 여부 변경 중 오류 발생:", error);
    }
  };

    const updateGenderField = async (id: string, value: boolean) => {
    try {
      const applyDoc = doc(db, "apply", id);
      await updateDoc(applyDoc, {["isMale"]: value});

      setApplies((prev) =>
        prev.map((row) =>
          row.id === id ? {...row, ["isMale"]: value} : row
        )
      );
    } catch (error) {
      console.error("필드 업데이트 중 오류 발생:", error);
    }
  }

  const updateApplyField = async (id: string, field: string, value: string | number) => {
    try {
      const applyDoc = doc(db, "apply", id);
      await updateDoc(applyDoc, {[field]: value});

      setApplies((prev) =>
        prev.map((row) =>
          row.id === id ? {...row, [field]: value} : row
        )
      );
    } catch (error) {
      console.error("필드 업데이트 중 오류 발생:", error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, "apply", id));

      // 이상형정보 삭제
      const notesCollection = collection(db, "ideal_notes");
      const q1 = query(notesCollection, where("userId", "==", id));

      const querySnapshot1 = await getDocs(q1);

      const deletePromises1 = querySnapshot1.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises1);

      // 회원정보 삭제
      const memberCollection = collection(db, "member_notes");
      const q2 = query(memberCollection, where("userId", "==", id));

      const querySnapshot2 = await getDocs(q2);

      const deletePromises2 = querySnapshot2.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises2);

      // 매칭 이력 삭제
      const histoiryCollection = collection(db, "match_history");
      const q3 = query(histoiryCollection, where("userId", "==", id));

      const querySnapshot3 = await getDocs(q3);

      const deletePromises3 = querySnapshot3.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises3);

      // 프로필 이력 삭제
      const profileCollection = collection(db, "profile_history");
      const q4 = query(profileCollection, where("userId", "==", id));

      const querySnapshot4 = await getDocs(q4);

      const deletePromises4 = querySnapshot4.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises4);

      setApplies((prev) => prev.filter((row) => row.id !== id));
    } catch (e) {
      console.error("행 삭제 중 오류 발생", e);
    }
  }

  const handleOpenModal = (type: string, userId: string) => {
    setSelectedUserId(userId);
    if (type === '회원 정보') {
      setOpenMemberInfo(true);
    } else if (type === "이상형 정보") {
      setOpenIdealInfo(true);
    } else if (type === '매칭 이력') {
      setOpenMatchHistory(true);
    } else if (type === '프로필') {
      setOpenProfileHistory(true)
    } else if (type === '사진') {
      setOpenPhotoModal(true);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "isMale",
      headerName: "성별",
      flex: 0.5,
      renderCell: (params) => (
        <Select
          value={params.value === undefined ? "X" : params.value ? "남" : "여"}
          onChange={(event: SelectChangeEvent) => {
            const newValue = event.target.value === "남";
            updateDoc(doc(db, "apply", params.row.id), { isMale: newValue });
            setApplies((prev) =>
              prev.map((row) => (row.id === params.row.id ? { ...row, isMale: newValue } : row))
            );
          }}
          sx={{ fontSize: "14px" }}
        >
          {params.value === undefined && <MenuItem value="X">X</MenuItem>}
          <MenuItem value="남">남</MenuItem>
          <MenuItem value="여">여</MenuItem>
        </Select>
      ),
    },
    { field: "name", headerName: "성함", flex: 1 },
    { field: "phoneNum", headerName: "연락처", flex: 1.5 },
    {
      field: "createdAt",
      headerName: "등록 일자",
      minWidth: 120,
      valueFormatter: (value) => {
        const date = new Date(value as number);
        return new Intl.DateTimeFormat("ko-KR", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        }).format(date).replace(/\./g, "").replace(/ /g, ".");
      },
    },
    {
      field: "ticket",
      headerName: "티켓",
      flex: 1,
      renderCell: (params) => (
        <Select
          value={params.value || "미결제"}
          onChange={(event: SelectChangeEvent) =>
            updateApplyField(params.row.id, "ticket", event.target.value)
          }
          sx={{width: "100px", fontSize: "14px"}}
        >
          <MenuItem value="미결제">미결제</MenuItem>
          <MenuItem value="class.1">class.1</MenuItem>
          <MenuItem value="class.2">class.2</MenuItem>
          <MenuItem value="class.3">class.3</MenuItem>
        </Select>
      ),
    },

    {
      field: "count",
      headerName: "횟수",
      flex: 0.7,
      renderCell: (params) => (
        <Select
          value={params.value || 0}
          onChange={(event: SelectChangeEvent) =>
            updateApplyField(params.row.id, "count", event.target.value)
          }
          sx={{width: "80px", fontSize: "14px"}}
        >
          <MenuItem value={0}>-</MenuItem>
          <MenuItem value={1}>1회</MenuItem>
          <MenuItem value={3}>3회</MenuItem>
          <MenuItem value={5}>5회</MenuItem>
        </Select>
      ),
    },
    {
      field: "memberInfo",
      headerName: "회원 정보",
      flex: 1,
      renderCell: (params) => (
        <button
          style={{
            padding: "4px 8px",
            background: "#323232",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleOpenModal("회원 정보", params.row.id)}
        >
          회원 정보 추가
        </button>
      )
    },
    {
      field: "idealInfo",
      headerName: "이상형 정보",
      flex: 1,
      renderCell: (params) => (
        <button
          style={{
            padding: "4px 8px",
            background: "#656565",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleOpenModal("이상형 정보", params.row.id)}
        >
          이상형 정보 추가
        </button>
      )
    },
    {
      field: "match_history",
      headerName: "매칭 이력",
      flex: 1,
      renderCell: (params) => (
        <button
          style={{
            padding: "4px 8px",
            background: "#8f8f8f",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleOpenModal("매칭 이력", params.row.id)}
        >
          매칭 이력
        </button>
      )
    },
    {
      field: "profile_history",
      headerName: "프로필",
      flex: 1,
      renderCell: (params) => (
        <button
          style={{
            padding: "4px 8px",
            background: "#8f8f8f",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleOpenModal("프로필", params.row.id)}
        >
          프로필
        </button>
      )
    },
    {
      field: "photo",
      headerName: "사진",
      flex: 0.5,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenModal("사진", params.row.id)}>
          <CameraAlt />
        </IconButton>
      ),
    },
    {
      field: "checked",
      headerName: "확인 여부",
      flex: 0.6,
      renderCell: (params) => (
        <span
          style={{
            color: params.value ? "#AAAAAA" : "inherit",
            cursor: "pointer",
          }}
          onClick={() => toggleChecked(params.row.id, params.value as boolean)}
        >
        {params.value ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <ToggleOn sx={{width: 24, height: 24, color: "#83A7DD"}}/> 확인됨
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <ToggleOff sx={{width: 24, height: 24, color: "#CCCCCC"}}/> 미확인
          </div>
        )}
      </span>
      ),
    },
    {
      field: "delete",
      headerName: "삭제",
      flex: 0.5,
      renderCell: (params) => (
        <button
          style={{
            padding: "4px 8px",
            background: "#ff4d4f",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => handleDelete(params.row.id)}
        >
          삭제
        </button>
      ),
    },
  ];

  return (
    <div className="admin">
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
        {/* ✅ 탭 추가 */}
        <Tabs value={selectedTab} onChange={handleTabChange} centered>
          <Tab label="전체" />
          <Tab label="남성 회원" />
          <Tab label="여성 회원" />
        </Tabs>

        <Box sx={{ width: "100%" }}>
          {!isAdmin ? (
            <div className="admin-login-content">
              <div className="admin-description">
                어드민 페이지에 접근하려면 <br /> Google 로그인을 해주세요.
              </div>
              <button className="admin-button" onClick={login}>
                Google 로그인
              </button>
            </div>
          ) : (
            <div className="admin-auth">
              <div className="list">
                <span>소개 요청 내역</span>
                <button onClick={logout} className="admin-button">
                  로그아웃
                </button>
              </div>
              {/* ✅ 필터링된 데이터로 Grid 렌더링 */}
              <StyledGrid
                rows={filteredApplies}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={[10, 20, 50]}
              />
            </div>
          )}
        </Box>
      </Box>

      <MemberInfoModal
        open={openMemberInfo}
        onClose={() => {
          setOpenMemberInfo(false);
          setSelectedUserId(null);
        }}
        userId={selectedUserId}
      />
      <IdealInfoModal
        open={openIdealInfo}
        onClose={() => {
          setOpenIdealInfo(false);
          setSelectedUserId(null);
        }}
        userId={selectedUserId}
      />
      <MatchHistoryModal
        open={openMatchHistory}
        onClose={() => {
          setOpenMatchHistory(false);
          setSelectedUserId(null);
        }}
        userId={selectedUserId}
      />
      <ProfileHistoryModal
        open={openProfileHistory}
        onClose={() => {
          setOpenProfileHistory(false);
          setSelectedUserId(null);
        }}
        userId={selectedUserId}
      />
      <PhotoModal
        open={openPhotoModal}
        onClose={() => setOpenPhotoModal(false)}
        userId={selectedUserId}
      />
    </div>
  );
};

export default AdminPage;
