import "../styles/footer.css";

type Props = {
  isMobile?: boolean;
}

const Footer = ({isMobile = false}: Props) => {

  return (
    <div className={'footer'}>
      <div className={'footer-container'}>
        <div className={'company'}>어나더라운지</div>
        <div className={'info'}>
          <div className={'info-row'}>상호명 : 어나더라운지 | 대표이사 : 박경지
            {isMobile ?
              <div className={'info-row'}><br/>사업자등록번호 : 267-16-02432</div> :
              <span> | 사업자등록번호 : 267-16-02432</span>}
          </div>
              <div className={'info-row'}>주소: 서울특별시 강서구 금낭화로 287-19, 504동511호</div>
          <div className={'info-row'}> https://anotherlounge.kr</div>
        </div>
      </div>
    </div>
  )
}

export default Footer;