import Logo from "../assets/logo.svg";
import "../styles/navigation.css";
import {useNavigate} from "react-router-dom";


const Navigation = () => {
  const navigate = useNavigate();
  return (
    <div className={'navigation'}>
      <img className={'logo'} src={Logo} alt={'AnotherClass'}
      onClick={() => navigate("/")}
      />
      <div className={'navigation-menus'}>
        <div className={'navigation-menu'}
        onClick={() => navigate("/criteria")}>입회 기준</div>
        <div className={'navigation-menu'}
        onClick={() => navigate("/early-trial")}>체험하기</div>
      </div>
    </div>
  )
}

export default Navigation;