import "../styles/marquee.css";

type Props = {
  isMobile: boolean;
  date?: boolean;
}

const Marquee = ({isMobile, date=false}: Props) => {
  const marqueeContent = [
    "가입비 제로",
    "신원 인증",
    "매력과 외모 인증",
    "서류 인증 회원 골드 테두리",
    "경제력 인증 마크",
    "프라이빗 플래너 매칭",
  ]

  return (
    <div className={'marquee-banner-bottom'}>
      {date ? <div className={'date'}>2025.03.01</div> : <></>}
      {!isMobile && <div style={{fontSize: '16px'}}>공식 출시 전에도 가입없이 어나더라운지를 이용해보세요.</div>}
      <div className="marquee-content">
        {marqueeContent.map((item: string, index: number) => (
          <div key={`item-${index}`} className="marquee-item">
            {item}
          </div>
        ))}
        {marqueeContent.map((item: string, index: number) => (
          <div key={`item-duplicate-${index}`} className="marquee-item">
            {item}
          </div>
        ))}
      </div>
    </div>
  )
}
export default Marquee;