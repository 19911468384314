import "../styles/card.css";
import useScrollFadeIn from "../utils/useScrollFadeIn";

const Card = () => {

  return (
    <div className={'card-container'} {...useScrollFadeIn("up", 1, 0)} >
      <div className={'card-title'} style={{textAlign: 'start'}}>
        라이프스타일, 성격, 가치관, 종교, 경제력.. <br/>
        <span style={{marginLeft: '120px'}}>인연에도 필터링 기능이 있다면 얼마나 좋을까</span>
      </div>
      <div className={'card-mid'} style={{opacity: '0.6'}}>가벼운 연애에 지친 당신, 진지한 연애가 바탕인 이곳에서 예상치 못한 인연이 찾아옵니다.</div>
      <div className={'card-content'}>
        <div className={'card-content-top'}>
          <div className={'card-content-box image-1'}>
            <div className={'card-desc'}>인연이 될 사람은 중요하니까</div>
            <div className={'card-subtitle'} style={{textAlign: 'end'}}>취향<br/>맞춤형<br/>서비스</div>
          </div>
          <div className={'card-content-box image-2'}>
            <div className={'card-desc'}>AI로 대체할 수 없는 매니저 매칭</div>
            <div className={'card-subtitle'} style={{textAlign: 'end'}}>디테일<br/>정밀함<br/><br/></div>
          </div>
          <div className={'card-content-box image-3'}>
            <div className={'card-desc'}>누가봐도 괜찮은 사람들의 모임</div>
            <div className={'card-subtitle'} style={{textAlign: 'end'}}>신원<br/>경제력<br/>매력</div>
          </div>
        </div>
        <div className={'card-content-bottom'}>
          <div className={'card-mid'}>복잡한 결정사의 대면 미팅 없이 원하는 인연을 만나는 결합형 데이팅앱</div>
          <div className={'card-subtitle'}>나의 능력을 인증하고 간편한 메세지로 더 솔직하고 상세하게</div>
        </div>
      </div>
    </div>
  )
}

export default Card;