import "../styles/download-button.css";
import {useNavigate} from "react-router-dom";

type DownloadBtnProps = {
  icon: string;
  url: string;
}

const DownloadBtn = (props: DownloadBtnProps) => {
  const navigate = useNavigate();
  return (
    <div className={'download-btn'}
    onClick={() => {navigate("/early-trial")}}>
      <img className={'download-btn-img'} src={props.icon} alt={''}/>
      Download
    </div>
  )
}

export default DownloadBtn;