import "../styles/matching.css";
import useScrollFadeIn from "../utils/useScrollFadeIn";

type Props = {
  isMobile: boolean;
}
const Matching = ({isMobile}: Props) => {

  return (
    <div className={'matching'} {...useScrollFadeIn("up", 1, 0)} >
      <div className={'matching-box'}>
        <div className={'matching-box-left'}>
          <div className={'left-first'}>직업 50%</div>
          <div className={'left-second'}>능력 40%</div>
          <div className={'left-third'}>외모 10%</div>
        </div>
        <div className={'matching-box-line-1'}/>
        <div className={'matching-box-line-2'}/>
        <div className={'matching-box-right'}>
          <div className={'right-first'}/>
          <div className={'right-second'}/>
          <div className={'right-third'}>매칭 진행 {isMobile ? '': '중'}</div>
        </div>
      </div>
    </div>
  )
}

export default Matching;