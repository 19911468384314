import "../styles/modal.css";
import {Dispatch, SetStateAction} from "react";

type Props = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setChecked: Dispatch<SetStateAction<boolean>>;
  isMobile?: boolean;
}
const Modal = ({setOpen, setChecked, isMobile=false}: Props) => {

  return (
    <div className={'modal-bg'}
         onClick={(e) => {
           e.preventDefault();
           setOpen(false);
         }}>
      <div className={'modal'} onClick={(e) => e.stopPropagation()}>
        <div className={'modal-title'}>개인정보 수집 및 이용 동의 안내</div>
        <div className={'modal-desc'}>
          본 동의 이후부터 플래너에게 연락을 받아보실 수 있습니다. {!isMobile && <br/>}
          아래와 같이 개인 정보의 수집 및 이용이 발생하니<br/>자세히 읽은 후 동의하여 주시기 바랍니다.
        </div>
        <div className={'policy'}>
          <div className={'policy-num'}>1</div>
          <div className={'policy-text'}>수집하는 개인정보<br/>
            <span style={{opacity: .6}}>
            이용자의 성함과 전화번호, 번호로 등록되어 있는 메신저 자동 동기화</span>
          </div>
        </div>
        <div className={'policy'}>
          <div className={'policy-num'}>2</div>
          <div className={'policy-text'}>수집한 개인정보의 이용 <br/>
            <span style={{opacity: .6}}>
              어나더라운지 서비스 이용을 지원하기 위한 목적으로 개인정보를 이용합니다.
              </span>
          </div>
        </div>
        <div className={'policy'}>
          <div className={'policy-num'}>3</div>
          <div className={'policy-text'}>개인정보의 보유기간<br/>
            <span style={{opacity: .6}}>
            안전한 서비스 이용 환경 지원을 위하여 수집 시점으로부터 1년간 보관하고 파기합니다.
            파기 이전에 플래너를 통해 요청하여 개인정보를 파기할 수 있습니다.
              </span>
          </div>
        </div>
        <div className={'modal-buttons'}>
          <div className={'cancel hover'} onClick={() => setOpen(false)}>닫기</div>
          <div className={'confirm hover'} onClick={() => {
            setChecked(true);
            setOpen(false);
          }}>동의하기
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;