export function formatPhoneNumber(text: string): string {
  const isSeoulNZ = text.slice(0, 2) === "02";
  const nationZoneRgex = isSeoulNZ ? "(\\d{1,2})" : "(\\d{1,3})";
  const phonePattern = new RegExp(`^${nationZoneRgex}(\\d{0,4})(\\d{0,4}).*$`);
  return text
    .replace(/[^0-9]/g, "")
    .replace(phonePattern, "$1-$2-$3")
    .replace(/^-/, "")
    .replace(/-$/, "")
    .replace(/-$/, "");
}
