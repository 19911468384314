import "../styles/criteria.css";
import Navigation from "./Navigation";
import BgMobile from "../assets/mobile-bg-top.png";
import Bg from "../assets/bg-top.png";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import Content1 from "../assets/content1.png";
import Content2 from "../assets/content2.png";
import Content3 from "../assets/content3.png";
import Content4 from "../assets/content4.png";
import Content5 from "../assets/content5.png";
import Content6 from "../assets/content6.png";
import Content7 from "../assets/content7.png";
import ScrollDown from "../assets/scroll-down.svg";
import Verified from "../assets/verified-check.svg";
import VerifiedPaper from "../assets/verified.png";
import VerifiedSig from "../assets/verified-sig.png";
import "../styles/coming-soon-page.css";
import Marquee from "./Marquee";
import useScrollFadeIn from "../utils/useScrollFadeIn";


type Props = {
  isMobile: boolean;
}

const CriteriaPage = ({isMobile}: Props) => {
  const marqueeContent = [
    "가입비 제로",
    "신원 인증",
    "매력과 외모 인증",
    "서류 인증 회원 골드 테두리",
    "경제력 인증 마크",
    "프라이빗 플래너 매칭",
  ]
  const [currentIndex, setCurrentIndex] = useState(0);
  const contentImg = [
    Content1, Content2, Content3, Content4, Content5, Content6, Content7
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentImg.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [contentImg.length]);


  const Criteria = (title: string, subtitle: string, bool: boolean = false) => {
    return (
      <div className={'criteria-component'} {...useScrollFadeIn("up", 1, 0)}>
        <div className={'criteria-component-title'}>
          <img className={'criteria-component-title-icon'} src={Verified} alt={''}/>
          <div className={'criteria-component-title-text'}>{title}</div>
        </div>
        <div className={'criteria-boolean'}>{bool ? '단독 가입 불가' : <br/>}</div>
        <div className={'criteria-component-content'}>{subtitle.split('\n').map((sub: string) => {
          return (
            <span>{sub} <br/> </span>
          )
        })}</div>
        <img className={'criteria-component-verified'} src={VerifiedPaper} alt={''}/>
      </div>
    )
  }

  const CriteriaRow = (title: string, subtitle: string) => {
    return (
      <div className={'row-criteria'}>
        <div className={'criteria-component-title'}>
          <img className={'criteria-component-title-icon'} src={Verified} alt={''}/>
          <div className={'criteria-component-title-text'}>{title}</div>
        </div>
        <div className={'criteria-component-content'}>{subtitle}</div>
        <img className={'criteria-component-sig'} src={VerifiedSig} alt={''}/>
      </div>
    )
  }
  return (
    <div className={'criteria'}>
      <Navigation/>
      <img className={'criteria-bg-top'} src={isMobile ? BgMobile : Bg} alt={''}/>
      <div className={'criteria-page-content'}>
        <div className={'criteria-banner'}>
          <div className={'criteria-banner-title'}>어나더 라운지<br/>입회 기준 안내</div>
          <div className={'criteria-banner-bottom'}>
            <Marquee isMobile={isMobile}/>
          </div>
        </div>
        <div className={'criteria-scroll'}>
          <div className={'text-sm'}>입회 기준은 어나더 라운지가 정한 방침에 따릅니다.</div>
          <div className={'text-sm'} style={{color: 'var(--primary)'}}>페이지 하단 참조</div>
          <img className={'criteria-scroll-icon'} src={ScrollDown} alt={''}/>
        </div>
        <div className={'criteria-top'}>
          {Criteria("수입차", "[수입차] 상위 11%", true)}
          {Criteria("강남거주", "[강남거주] 상위 3%", true)}
          {Criteria("고급 아파트", "[고급아파트] 상위 3.6%", false)}
          {Criteria("전문직, 사업가", "[매출 30억 이상] \n [은행권 인정 전문직] 상위 1%", false)}
          {Criteria("대기업, 공무원", "상위 15%", false)}
          {Criteria("고소득", "[연봉7천이상] 상위15%", false)}
          {Criteria("억대연봉", "[연봉1억이상] 상위 6%", false)}
          {Criteria("고액 자산", "[개인 5억 이상] 상위 15%", false)}
          {Criteria("초고액 자산", "[개인 20억 이상] 상위 5%", false)}
          {Criteria("명문대", "[SKY 외 주요] 상위 10%", false)}
          {Criteria("엘리트 집안", "[전문직집안] 상위 5%", false)}
          {Criteria("자산가 집안", "[순자산 50억] 상위 5%", false)}
        </div>
        <div className={'criteria-divider'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'criteria-row'}/>
          <div className={'criteria-divider-title'}>ANOTHER LOUNGE</div>
          <div className={'criteria-row'}/>
        </div>
        <div className={'criteria-bottom'} {...useScrollFadeIn("up", 1, 0)}>
          {CriteriaRow("1조 클럽 회원", "기업가치 1조 이상의 기업대표 또는 주요 주주")}
          {CriteriaRow("개인자산 1천억 이상", "부동산,주식,코인 등 현금화 가능한 자산으로 인증")}
        </div>
        <div className={'criteria-divider'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'criteria-row'}/>
          <div className={'criteria-divider-title'}>여성회원 입회 조건</div>
          <div className={'criteria-row'}/>
        </div>
        <div className={'criteria-bottom'} {...useScrollFadeIn("up", 1, 0)}>
          {CriteriaRow("외모 인증", "외모 인증을 통한 프로필 심사")}
          {CriteriaRow("기타 서류 인증", "남성 회원 입회 기준과 동일한 검증 선택 가능")}
        </div>
      </div>
      <Footer isMobile={isMobile}/>
    </div>
  )
}

export default CriteriaPage;