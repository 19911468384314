import "../styles/mobile.css";
import Navigation from "./Navigation";
import Bg from "../assets/mobile-bg.png";
import DownloadBtn from "./DownloadBtn";
import PlayStore from "../assets/icon-google-play.png";
import AppStore from "../assets/icon-app-store.png";
import Down from "../assets/icon-down.svg";
import UserProfile from "../assets/user-profile.png";
import Content71 from "../assets/m-seventh-content.png";
import DailyCard from "../assets/daily-card.png";
import LovePlanner from "../assets/love-planner.png";
import Community from "../assets/community.png";
import Review from "../assets/m-review.png";
import QR from "../assets/m-qr.png";
import Footer from "./Footer";
import BG2 from "../assets/mobile-bg2.png";
import Manager from "../assets/manager.png";
import {useEffect, useRef, useState} from "react";
import Scroll from "./Scroll";
import useScrollFadeIn from "../utils/useScrollFadeIn";
import Tags from "../assets/scroll-web.png";
import Matching from "./Matching";
import {useLocation} from "react-router-dom";
import Modal from "./Modal";
import MobileInput from "./MobileInput";
import ArrowDown from "../assets/arrow-down.svg";
import useViewportHeight from "../utils/useViewportHeight";
import SubmitModal from "./SubmitModal";


const MobileLanding = () => {
  useViewportHeight();
  const location = useLocation();
  const bottomRef = useRef<HTMLDivElement>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isShortcutVisible, setIsShortcutVisible] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (location.state?.scrollToBottom) {
      bottomRef.current?.scrollIntoView();
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (!bottomRef.current) return;

      const bottomPosition = bottomRef.current.getBoundingClientRect().top; // bottomRef의 top 위치
      const isPastBottom = bottomPosition - 320 < 0; // ✅ bottomRef가 화면 위로 지나갔을 때

      setIsShortcutVisible(!isPastBottom);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <div className={'m-body'}>
      <Navigation/>
      <div className={`shortcut ${isShortcutVisible ? "" : 'hidden'}`} onClick={() => {
        bottomRef.current?.scrollIntoView();
      }}>
        소개요청 바로가기
        <img className={'shortcut-scroll'} src={ArrowDown} alt={''}/>
      </div>
      <img className={'m-background'} src={Bg} alt={''}/>
      <div className={'m-another-class-content'}>
        <div className={'m-first'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'m-title-box'}>
            <div className={'m-title-1'}>나의 가치를 더 빛내줄 인연</div>
            <div className={'m-title-2'}>남다른 사람들의 데이팅앱</div>
            <div className={'m-title-3 glowing'}>어나더 라운지</div>
          </div>
          <div className={'download-buttons'}>
            <DownloadBtn icon={PlayStore} url={''}/>
            <DownloadBtn icon={AppStore} url={''}/>
          </div>
        </div>
        <div className={'m-second'} {...useScrollFadeIn("up", 1, 0.5)}>
          <div className={'text-24'}>
            <span style={{color: 'var(--secondary)'}}>자격</span> 있는 남녀의 <br/>
            <span style={{color: 'var(--secondary)'}}>품격</span> 있는 소개팅
          </div>
          <div className={'m-second-content'}>
            <div className={'text-16'}>"서류 검증 기반 하이엔드 플래너 매칭 플랫폼"</div>
            <div className={'text-14'} style={{color: 'var(--white)'}}>능력 있는 당신, 특별한 만남에 한 발짝 다가오세요</div>
            <div className={'text-14'} style={{opacity: '.6', marginTop: '12px', textAlign: 'center'}}>
              2018년 5월 23일, 매월 1,000건 이상의 성공적인 매칭을 이뤄낸 하이엔드 커플 플래너 서비스가
              온라인으로 확장되었습니다. 엄격한 심사를 통해 경제력과 외모를 겸비한 회원을 선별하며,
              결혼정보사의 진중함과 데이팅 앱의 편리함을 결합한 최상의 매칭 경험을 제공합니다.
            </div>
          </div>
        </div>
        <div className={'m-third'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'text-24'}>
            철저한 <span style={{color: 'var(--secondary)'}}>인증</span> 프로세스
          </div>
          <div className={'m-third-2 text-14 opacity-60'}>
            신뢰 기반 인증 서류인 8가지 항목과 <br/>
            20개 이상의 세부 기준으로 구성된 어나더라운지는 <br/>
            믿을만한 인연을 만들어 드려요.
          </div>
          <Scroll/>
        </div>
        <div className={'m-fourth'} {...useScrollFadeIn("up", 1, 0)}>
          <img className={'m-fourth-bg'} src={BG2} alt={''}/>
          <div className={'m-fourth-mask'}>
            <img className={'m-fourth-tag-screen'} src={Tags} alt={''}/>
          </div>
          <div className={'m-divider'}/>
          <img className={'m-fourth-icon'} src={Down} alt={''}/>
        </div>
        <div className={'m-fifth'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'text-20'}>
            맞춤 인연, 지금 만나세요.
          </div>
          <div className={'text-24'}>새로운 형태의 소개팅 App</div>
          <div className={'text-14 opacity-60'} style={{textAlign: 'center'}}>
            가벼운 연애에 지친 당신, 진지한 연애가 바탕인 이곳에서<br/>예상치 못한 인연이 찾아옵니다.
          </div>
          <div className={'m-fifth-content'}>
            <div className={'m-fifth-content-top'}>
              <div className={'m-fifth-content-box m-image-1'} {...useScrollFadeIn("right", 1, 0)}>
                <div className={'text-14'}>인연이 될 사람은 중요하니까</div>
                <div className={'text-24'} style={{textAlign: 'end'}}>취향<br/>맞춤형<br/>서비스</div>
              </div>
              <div className={'m-fifth-content-box m-image-2'} {...useScrollFadeIn("right", 1, 0)}>
                <div className={'text-14'}>AI로 대체할 수 없는 매니저 매칭</div>
                <div className={'text-24'} style={{textAlign: 'end'}}>디테일<br/>정밀함<br/></div>
              </div>
              <div className={'m-fifth-content-box m-image-3'} {...useScrollFadeIn("right", 1, 0)}>
                <div className={'text-14'}>누가봐도 괜찮은 사람들의 모임</div>
                <div className={'text-24'} style={{textAlign: 'end'}}>신원<br/>경제력<br/>매력</div>
              </div>
            </div>
            <div className={'m-fifth-content-bottom'} {...useScrollFadeIn("right", 1, 0)}>
              대면 미팅 없이 원하는 인연을 만날 수 있는<br/>
              결합형 데이팅 앱. 내 능력을 인증하고,<br/>
              간편한 메시지로 솔직하게 소통하세요.
            </div>
          </div>
        </div>
        <div className={'m-sixth'}>
          <div className={'text-24'} style={{textAlign: 'center'}}>
            결정사의 무게감은 그대로, <br/>데이팅앱의 편리함이 합쳐진<br/>
            <span style={{color: 'var(--secondary)'}}>하이엔드 매칭</span>
          </div>
          <div className={'text-20 opacity-60'} style={{textAlign: 'center'}}>회원님의 이상형을 토대로<br/>인연을 설계합니다.</div>
          <div className={'m-sixth-content-box'} {...useScrollFadeIn("up", 1, 0)}>
            <div className={'m-divider'}/>
            <div className={'m-figure'}/>
            <div className={'m-chat-right'}>
              <div className={'text-14'}>H</div>
              <div className={'m-chatbox-right'}>
                안녕하세요 커플 플래너 서비스를<br/>이용해보려고 해요. 2개 이상의 뱃지를 가지신 분을 알아가보고 싶어요.
              </div>
            </div>
            <div className={'m-chat-left'}>
              <div className={'m-chat-left-profile'}>
                <img className={'m-chat-profile-img'} src={Manager} alt={''}/>
                <div className={'text-14'}>수석 매니저</div>
              </div>
              <div className={'m-chatbox-left'}>
                <div style={{color: 'var(--secondary)', fontSize: '14px', fontWeight: '600'}}>Interview</div>
                늦은 나이에 의사가 되어 결혼이 늦어졌지만, 이제는 준비를 마친 상태입니다.
                경제적·정서적 여유 속에서 함께 예쁘고 즐거운 삶을 나눌 분을 찾고 있으며, 내후년 결혼을 목표로 진지한
                미래를 그려갈 분을 만나고 싶습니다. 비슷한 목표를 가진 분들의 관심을 기다립니다.
                <div className={'user-profile'}>
                  <img className={'user-profile-img'} src={UserProfile} alt={''}/>
                  <div className={'user-profile-info-container'}>
                    <div className={'user-profile-info'}>
                      성명 : 강X석<br/>
                      나이 : 36살<br/>
                      거주지 : 서울시 강남구 XX<br/>
                      키 : 183cm<br/>
                      취미 : 축구, 테니스
                    </div>
                    <div className={'user-profile-info-row'}>
                      <div className={'user-profile-info-row-label'}>직업 / 학력</div>
                      <div className={'user-profile-info-value'}>
                        xx과학고 졸업 <br/>
                        서울대학교 졸업 <br/>
                        현)치과의사
                      </div>
                    </div>
                    <div className={'user-profile-info-row'}>
                      <div className={'user-profile-info-row-label'}>성장 배경</div>
                      <div className={'user-profile-info-value'}>
                        화목한 가정에서 자란 2남 1녀 중 첫째로,
                        교수인 어머니와 화가인 아버지께서 서로 다른 성향에도
                        가사와 식사 시간을 소중히 여기는 분들입니다. 부모님은 항상
                        자녀의 목표를 응원하며 선택을 지지해 주셨고, 노후 준비도 철저히 갖추셨습니다.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={'m-sixth-content-bottom'} {...useScrollFadeIn("up", 1, 0)}>
              <div
                className={'text-20'} style={{fontWeight: 600, marginTop: '48px', textAlign: 'center'}}>
                Q. 플래너를 통한 매칭은<br/>
                <span style={{color: '#DD99AD'}}>서비스 범주</span>가 어떻게 되나요?
              </div>
              <div className={'text-14 opacity-60'} style={{textAlign: 'center'}}>
                플래너와의 상담을 통해 이상형과 회원님의 정보를
                바탕으로 매칭이 진행돼요. 선택 후 만남의 시간과 장소까지
                <br/>세부적으로 조율해드려요.
              </div>
              <div className={'m-sixth-content-box-2'}>
                <div className={'m-sixth-bg'} {...useScrollFadeIn("down", 1, 0)}>
                  <div className={'m-sixth-content-2'}>상담 및<br/>니즈 파악</div>
                </div>
                <div className={'m-sixth-bg'} {...useScrollFadeIn("down", 1, 0.4)}>
                  <div className={'m-sixth-content-2'}>상호 매칭</div>
                </div>
                <div className={'m-sixth-bg'} {...useScrollFadeIn("down", 1, 0.8)}>
                  <div className={'m-sixth-content-2'}>일정 조율 및<br/>장소 협의</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'m-seventh'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'m-seventh-title'}>
            <div className={'text-24'}>
              <span style={{color: 'var(--secondary)'}}>취향과 일치</span>하는 소개팅
            </div>
            <div className={'text-20'}>가치 있고 합리적인 서비스를 제공합니다.</div>
            <div className={'text-14 opacity-60'} style={{textAlign: 'center', whiteSpace: 'nowrap'}}>
              원하는 이성의 상세한 디테일과 뱃지를 플래너에게 요청하세요.<br/>합리적이고 효율적인 육각형 소개팅을 할 수 있어요.
            </div>
          </div>
          <img className={'m-seventh-content-1'} style={{width: '100%', maxWidth: '100%'}} src={Content71} alt={''}/>
          <Matching isMobile={true}/>
        </div>
        <div className={'m-eighth'}>
          <div className={'m-eighth-content'} {...useScrollFadeIn("up", 1, 0)}>
            <div className={'m-eighth-content-box'}>
              <div className={'text-28'} style={{whiteSpace: 'nowrap'}}>Daily Card</div>
              <div className={'eighth-text text-14 opacity-60'}>
                까다로운 심사를 통과한 <br/>회원분들을 소개합니다. <br/><br/>수만명의 이성중 나에게 도달한 오늘의 인연을 확인하세요.
              </div>
            </div>
            <img className={'m-eighth-img'} src={DailyCard} alt={''}/>
          </div>
          <div className={'m-eighth-content center'} {...useScrollFadeIn("up", 1, 0)}>
            <img className={'m-eighth-img'} src={LovePlanner} alt={''}/>
            <div className={'m-eighth-content-box'}>
              <div className={'text-28'} style={{whiteSpace: 'nowrap'}}>Love Planner</div>
              <div className={'eighth-text text-14 opacity-60'}>
                나만의 플래너와 함께 취향에 딱 맞는 이상형을 만나보세요.
              </div>
            </div>
          </div>
          <div className={'m-eighth-content end'} {...useScrollFadeIn("up", 1, 0)}>
            <div className={'m-eighth-content-box'}>
              <div className={'text-28'} style={{whiteSpace: 'nowrap'}}>Community</div>
              <div className={'eighth-text text-14 opacity-60'}>
                호감의 시작은 데일리카드에서, 인연의 시작은 커뮤니티에서 다양한 주제로 이야기하고 서로의 취향과 성격을 알아보세요.
              </div>
            </div>
            <img className={'m-eighth-img'} src={Community} alt={''}/>
          </div>
        </div>
        <div className={'m-ninth'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'text-16 opacity-60'} style={{textAlign: 'center'}}>
            2018년 부터 이어온<br/>어나더 라운지에서 시작된 회원님들의<br/>특별한 후기 입니다.
          </div>
          <div className={'marquee-content'}>
            <img className={'m-review'} src={Review} alt={''}
                 style={{width: '100%', maxWidth: '100%'}}/>
            <img className={'m-review'} src={Review} alt={''}
                 style={{width: '100%', maxWidth: '100%'}}/>
          </div>
        </div>
        <div className={'bottomRef'} ref={bottomRef}>&nbsp;</div>
        <div className={'m-tenth'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'m-tenth-title'}>
            <div className={'m-tenth-title-1'}>소개요청</div>
            <div className={'m-tenth-title-2'}>
              어나더라운지를 체험해보세요.
            </div>
            <div className={'text-16 opacity-60'} style={{whiteSpace: 'nowrap'}}>앱 내 사용 가능한 <br/>최대 20만원 상당의 재화를 제공해드립니다.</div>
          </div>
          <MobileInput checked={checked} setChecked={setChecked} setOpen={setOpen} setSubmitted={setSubmitted}/>
        </div>
        <div className={'download'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'download-text'}>사전 예약<br/>상담 신청</div>
          <img className={'qr-code'} src={QR} alt={''}/>
        </div>
      </div>
      <Footer/>
      {open && (
        <Modal setOpen={setOpen} setChecked={setChecked} isMobile={true}/>
      )}
      {submitted && (
        <SubmitModal setOpen={setSubmitted} />
      )}
    </div>
  )
}

export default MobileLanding;