import React, {useEffect, useState} from 'react';
import './App.css';
import Landing from "./components/Landing";
import MobileLanding from "./components/MobileLanding";
import {AdminProvider} from "./utils/AdminContext";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AdminPage from "./components/AdminPage";
import ComingSoonPage from "./components/ComingSoonPage";
import CriteriaPage from "./components/CriteriaPage";

function App() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AdminProvider>
      <Router>
        <Routes>
          <Route path={"/"} element={isMobile ? <MobileLanding/> : <Landing/>}/>
          <Route path={'/admin'} element={<AdminPage/>}/>
          <Route path={'/early-trial'} element={<ComingSoonPage isMobile={isMobile}/>}/>
          <Route path={'/criteria'} element={<CriteriaPage isMobile={isMobile}/>}/>
          <Route path={'*'} element={isMobile ? <MobileLanding/> : <Landing/>}/>
        </Routes>
      </Router>
    </AdminProvider>
  )
}

export default App;
