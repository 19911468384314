import React, { useEffect, useState } from "react";
import {
  Modal, Box, TextareaAutosize, Button, Divider, Typography, IconButton, TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { collection, addDoc, query, getDocs, where, updateDoc, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebase";

type ProfileHistoryNote = {
  id: string;
  content: string;
  createdAt: number;
};

type Props = {
  open: boolean;
  onClose: () => void;
  userId: string | null;
};

const ProfileHistoryModal: React.FC<Props> = ({ open, onClose, userId }) => {
  const [content, setContent] = useState("");
  const [notes, setNotes] = useState<ProfileHistoryNote[]>([]);
  const [editMode, setEditMode] = useState<string | null>(null);
  const [editContent, setEditContent] = useState("");

  useEffect(() => {
    if (open) {
      fetchNotes();
    }
  }, [open]);

  const fetchNotes = async () => {
    try {
      if (!userId) return;
      const notesCollection = collection(db, "profile_history");
      const q = query(notesCollection, where("userId", "==", userId));
      const querySnapshot = await getDocs(q);

      let fetchedNotes: ProfileHistoryNote[] = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as ProfileHistoryNote[];

      fetchedNotes = fetchedNotes.sort((a, b) => b.createdAt - a.createdAt);
      setNotes(fetchedNotes);
    } catch (error) {
      console.error("프로필 내역 불러오기 실패:", error);
    }
  };

  const handleRegister = async () => {
    if (!content.trim() || !userId) return;

    try {
      const newNote = {
        userId,
        content,
        createdAt: Date.now(),
      };
      const docRef = await addDoc(collection(db, "profile_history"), newNote);

      setNotes([{ id: docRef.id, ...newNote }, ...notes]);
      setContent("");
    } catch (error) {
      console.error("프로필 내역 등록 실패:", error);
    }
  };

  const handleEdit = (note: ProfileHistoryNote) => {
    setEditMode(note.id);
    setEditContent(note.content);
  };

  const handleSaveEdit = async (id: string) => {
    try {
      const noteDoc = doc(db, "profile_history", id);
      await updateDoc(noteDoc, { content: editContent });

      setNotes((prev) =>
        prev.map((note) => (note.id === id ? { ...note, content: editContent } : note))
      );

      setEditMode(null);
      setEditContent("");
    } catch (error) {
      console.error("프로필 내역 수정 실패:", error);
    }
  };

  const handleDelete = async (id: string) => {
    const confirmDelete = window.confirm("정말 삭제하시겠습니까?");
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, "profile_history", id));

      setNotes((prev) => prev.filter((note) => note.id !== id));
    } catch (error) {
      console.error("프로필 내역 삭제 실패:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modal}>
        <Box sx={styles.header}>
          <Typography variant="h6" color="black">
            프로필 등록
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <TextareaAutosize
          minRows={4}
          placeholder="프로필 이력을 입력하세요..."
          value={content}
          onChange={(e) => setContent(e.target.value)}
          style={{ width: "100%", fontFamily: "Pretendard, sans-serif" }}
        />
        <Button variant="contained" onClick={handleRegister} fullWidth sx={{ background: "#FFA000" }}>
          등록
        </Button>

        <Divider sx={{ margin: "16px 0" }} />

        <Typography variant="subtitle1" color="black">
          지난 등록 내역
        </Typography>
        <Box sx={styles.noteList}>
          {notes.length === 0 ? (
            <Typography variant="body2" color="gray">
              아직 등록된 정보가 없습니다.
            </Typography>
          ) : (
            notes.map((note) => (
              <Box key={note.id} sx={styles.noteItem}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <Typography variant="caption" color="black">
                    {new Intl.DateTimeFormat("ko-KR", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    }).format(note.createdAt)}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "4px" }}>
                    {editMode !== note.id ? (
                      <>
                        <IconButton size="small" onClick={() => handleEdit(note)}>
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleDelete(note.id)}>
                          <DeleteIcon fontSize="small" color="error" />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton size="small" onClick={() => handleSaveEdit(note.id)}>
                          <SaveIcon fontSize="small" />
                        </IconButton>
                        <IconButton size="small" onClick={() => setEditMode(null)}>
                          <CancelIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>

                {editMode === note.id ? (
                  <TextField
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    fullWidth
                    multiline
                    variant="outlined"
                    size="small"
                  />
                ) : (
                  note.content.split("\n").map((content, index) => (
                    <Box>
                      <Typography key={index} variant="body2" color="black">
                        {content}
                      </Typography>
                    </Box>
                  ))
                )}
              </Box>
            ))
          )}
        </Box>
      </Box>
    </Modal>
  );
};

// ✅ 스타일 정의
const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    borderRadius: "8px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 2,
  },
  noteList: {
    mt: 2,
    maxHeight: "200px",
    overflowY: "auto",
  },
  noteItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    p: 1,
    borderBottom: "1px solid #ddd",
  },
};

export default ProfileHistoryModal;
