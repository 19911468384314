import "../styles/coming-soon-page.css";
import Navigation from "./Navigation";
import Bg from "../assets/bg-top.png";
import BgMobile from "../assets/mobile-bg-top.png";
import Content1 from "../assets/content1.png";
import Content2 from "../assets/content2.png";
import Content3 from "../assets/content3.png";
import Content4 from "../assets/content4.png";
import Content5 from "../assets/content5.png";
import Content6 from "../assets/content6.png";
import Content7 from "../assets/content7.png";
import ScrollDown from "../assets/scroll-down.svg";
import Phone from "../assets/icon-phone.svg";
import Work from "../assets/icon-work.svg";
import Step1 from "../assets/step-1.png";
import Step2 from "../assets/step-2.png";
import Step3 from "../assets/step-3.png";


import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useScrollFadeIn from "../utils/useScrollFadeIn";
import Footer from "./Footer";
import Marquee from "./Marquee";

type Props = {
  isMobile: boolean;
}

const ComingSoonPage = ({isMobile}: Props) => {


  const contentImg = [
    Content1, Content2, Content3, Content4, Content5, Content6, Content7
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/', {state: {scrollToBottom: true}});
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % contentImg.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [contentImg.length]);


  return (
    <div className={'coming-soon'}>
      <Navigation/>
      <img className={'bg-top'} src={isMobile ? BgMobile : Bg} alt={''}/>
      <div className={'page-content'}>
        <div className={'banner'}>
          <div className={'banner-title'}>COMING<br/>SOON</div>
          {/*<div className={'banner-bottom'}>*/}
          {/*  <div className={'date'}>2025.03.01</div>*/}
          {/*  {!isMobile && <div style={{fontSize: '16px'}}>공식 출시 전에도 가입없이 어나더라운지를 이용해보세요.</div> }*/}
          {/*  <div className="marquee-content">*/}
          {/*    {marqueeContent.map((item: string, index: number) => (*/}
          {/*      <div key={`item-${index}`} className="marquee-item">*/}
          {/*        {item}*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*    {marqueeContent.map((item: string, index: number) => (*/}
          {/*      <div key={`item-duplicate-${index}`} className="marquee-item">*/}
          {/*        {item}*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <Marquee isMobile={isMobile} date={true} />
        </div>
        <div className={'content-preview'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'content-preview-top'}>
            <div className={'text-sm'}>APP출시 전 어나더라운지를 {isMobile && <br/>}간단한 방법을 통해 체험해보세요.</div>
            <div className={'text-sm'} style={{color: 'var(--primary)'}}>이용 방법 페이지 하단 참조</div>
            <img className={'scroll-down'} src={ScrollDown} alt={''}/>
          </div>
          <div className={'content-frame'}>
            <div className="content-slider">
              {contentImg.concat(contentImg).concat(contentImg).concat(contentImg).map((src, index) => (
                <img key={index} className="content-img" src={src} alt={`Content ${index + 1}`}/>
              ))}
            </div>
          </div>
        </div>
        <div className={'mid-frame'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'text-lg'}>
            자격을 갖춘 이들을 <br/>위한 품격 있는 커뮤니티
          </div>
          <div className={'text-sm'} style={{opacity: .6}}>
            어나더라운지는 내부 규정에 부합하는 {isMobile && <br/>}회원 유지와 신뢰도 강화를 위해<br/>
            엄격한 서류 심사 및 회원 간 평가 시스템을 기반으로<br/>
            운영되는 프리미엄 데이팅 커뮤니티입니다.
          </div>
        </div>
        <div className={'process'} {...useScrollFadeIn("up", 1, 0)}>
          <div className={'text-sm'} style={{color: 'var(--primary)'}}>PROCESS</div>
          <div className={'text-lg'}>체험판에서 매칭은 {isMobile && <br/>}어떻게 진행되나요?</div>
          <div className={'text-sm'}>페이지 하단의 소개 문의란에 성함과 전화번호 입력</div>
          <div className={'navigate-btn'} onClick={handleClick}>소개 문의 페이지로 이동</div>
          <div className={'navigate-info'}>
            <div className={'navigate-info-item'}>
              <img className={'navigate-info-img'} src={Phone} alt={''}/>
              <div className={'navigate-info-text'}>
                <div className={'text-sm'}>신원 인증</div>
                <div className={'text-xs'} style={{color: 'var(--primary)'}}>핸드폰, 카카오톡 인증</div>
              </div>
            </div>
            <div className={'navigate-info-item'}>
              <img className={'navigate-info-img'} src={Work} alt={''}/>
              <div className={'navigate-info-text'}>
                <div className={'text-sm'}>직업, 경제력 인증</div>
                <div className={'text-xs'} style={{color: 'var(--primary)'}}>사원증, 명함, 재직증명서 등</div>
              </div>
            </div>
          </div>
        </div>
        <div className={'step'}>
          <div className={'step-row'} {...useScrollFadeIn("right", 1, 0)}>
            <img className={'step-img'} src={Step1} alt={''}/>
            <div className={'step-content'}>
              <div className={'step-title'}>
                <div className={'step-num'}>1</div>
                <div className={'text-sm'}>상담 진행</div>
              </div>
              <div className='step-desc' style={{opacity: '0.6'}}>
                정보를 입력하시면 플래너가 직접 연락을 드려요. <br/>
                이상형 정보, 또는 원하는 매칭 조건을 모두 말해보세요.
              </div>
            </div>
          </div>
          <div className={'step-row reverse'} {...useScrollFadeIn("left", 1, 0)}>
            <div className={`step-content ${!isMobile && 'center'}`}>
              <div className={'step-title'}>
                <div className={'step-num'}>2</div>
                <div className={'text-sm'}>매칭 성사 후 일정 조율</div>
              </div>
              <div className='step-desc' style={{opacity: '0.6'}}>
                서로의 거리, 취향을 고려해 매칭을 진행 합니다.<br/>
                또 가장 적합한 장소와 시간을 추천해드릴게요.
              </div>
            </div>
            <img className={'step-img'} src={Step2} alt={''}/>
          </div>
          <div className={'step-row'} {...useScrollFadeIn("right", 1, 0)}>
            <img className={'step-img'} src={Step3} alt={''}/>
            <div className={'step-content'}>
              <div className={'step-title'}>
                <div className={'step-num'}>3</div>
                <div className={'text-sm'}>소개팅 D-day</div>
              </div>
              <div className='step-desc' style={{opacity: '0.6'}}>
                소개팅 후 에프터 서비스를 제공합니다. <br/>
                선택적 후기 또는 피드백을 통해 <br/>가장 최적의 매칭을 주선 해드리고 있습니다.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer isMobile={isMobile}/>
    </div>
  )
}

export default ComingSoonPage;