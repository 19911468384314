import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import "../styles/floating.css";
import Checked from "../assets/icon-checked.svg";
import Unchecked from "../assets/icon-unchecked.svg";
import {addDoc, collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../firebase";
import {formatPhoneNumber} from "../utils/formatPhoneNumber";

type Props = {
  setFOpen: Dispatch<SetStateAction<boolean>>;
  setFChecked: Dispatch<SetStateAction<boolean>>;
  fChecked: boolean;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
}

const Floating = (props: Props) => {
  const {setFOpen, setFChecked, fChecked, setSubmitted} = props;
  const [top, setTop] = useState<number>(600);
  const [scrollY, setScrollY] = useState<number>(0);
  const [isVisible, setIsVisible] = useState<boolean>(true); // ✅ 보이기/숨기기 상태 추가
  const [fName, setFName] = useState<string>("");
  const [fPhone, setFPhone] = useState<string>("");
  const [male, setMale] = useState<boolean>(true);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!fName || !fPhone) {
      alert("성함과 전화번호를 모두 입력해주세요");
    }
    try {
      const applyCollection = collection(db, "apply");
      const q = query(applyCollection, where("phone_num", "==", fPhone));
      const existing = await getDocs(q);

      if (!existing.empty) {
        alert("이미 등록된 연락처입니다.");
        return;
      }

      await addDoc(applyCollection, {
        name: fName,
        phone_num: fPhone,
        isMale: male,
        checked: false,
        createdAt: Date.now()
      });
      if (typeof window !== "undefined" && typeof window.fbq === "function") {
        window.fbq("track", "CompleteRegistration");
        console.log("CompleteRegistration");
      } else {
        console.warn("Facebook Pixel이 아직 로드되지 않았습니다.");
      }
      setSubmitted(true);
      setFName("");
      setFPhone("");
      setMale(true);
    } catch (error) {
      console.error("요청 중 오류 발생: ", error);
    }
  }

  useEffect(() => {
    const bodyElement = document.querySelector(".body");
    const targetElement = document.querySelector(".bottomRef"); // ✅ 소개요청 섹션

    const handleScroll = () => {
      if (bodyElement && targetElement) {
        const yOffset = bodyElement.scrollTop;
        setScrollY(yOffset);

        const targetOffset = targetElement.getBoundingClientRect().top;
        if (targetOffset < window.innerHeight / 2) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      }
    };

    if (bodyElement) {
      bodyElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (bodyElement) {
        bodyElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setTop((prevTop) => prevTop + (scrollY + 600 - prevTop) * 0.2);
    }, 16); // 60FPS

    return () => clearInterval(interval);
  }, [scrollY]);


  return (
    <div className={`floating ${isVisible ? "" : "hidden"}`} style={{top: `${top}px`}}>
      <div className={'floating-title'}>빠른 소개 요청</div>
      <form className={'floating-form'} onSubmit={handleSubmit} autoComplete="off">
        <div className={'gender'}>
          <div className={`gender-option ${male && 'selected'}`}
               onClick={() => setMale(true)}>남성</div>
          <div className={`gender-option ${!male && 'selected'}`}
               onClick={() => setMale(false)}>여성</div>
        </div>
        <div className={'floating-input-box'}>
          <div className={'floating-label'}>성함</div>
          <input className={'floating-input'}
                 type={'text'} value={fName} onChange={(e) => setFName(e.target.value)}
                 maxLength={8} placeholder={'성함을 입력해주세요.'} required/>
        </div>
        <div className={'floating-input-box'}>
          <div className={'floating-label'}>연락처</div>
          <input className={'floating-input'}
                 type={'text'} value={formatPhoneNumber(fPhone)}
                 onChange={(e) => setFPhone(e.target.value)}
                 placeholder={'숫자만 입력하세요.'}/>
        </div>
        <div className={'text'} onClick={() => setFChecked(!fChecked)}>
          <img className={'floating-checkbox'}
               src={fChecked ? Checked : Unchecked} alt={''}/>
          <span className={'floating-a'}
                onClick={(e) => {
                  e.stopPropagation();
                  setFOpen(true);
                }}
          >개인정보 수집 및 이용</span>에
          동의합니다
        </div>
        <button className={'floating-button'} type={'submit'} disabled={!fChecked}>제출</button>
      </form>
    </div>
  );
};

export default Floating;
