import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged, signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

interface AdminContextProps {
  isAdmin: boolean;
  loading: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

const AdminContext = createContext<AdminContextProps>({
  isAdmin: false,
  loading: true,
  login: async () => {},
  logout: async () => {},
});

export const AdminProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(() => {
    const storedIsAdmin = localStorage.getItem("isAdmin");
    return storedIsAdmin === "true";
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const adminDoc = await getDoc(doc(db, "admins", user.email as string));
        const isUserAdmin = adminDoc.exists();
        setIsAdmin(isUserAdmin);
        localStorage.setItem("isAdmin", String(isUserAdmin));
      } else {
        setIsAdmin(false);
        localStorage.removeItem("isAdmin");
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const login = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    try {
      const result = await signInWithPopup(auth, provider);
      const userEmail = result.user.email;

      const adminDoc = await getDoc(doc(db, "admins", userEmail as string));
      const isUserAdmin = adminDoc.exists();

      setIsAdmin(isUserAdmin);
      localStorage.setItem("isAdmin", String(isUserAdmin));
      if (!isUserAdmin) {
        alert("관리자 권한이 없습니다.");
      }
    } catch (error) {
      console.error("로그인 실패:", error);
      alert("로그인에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    setLoading(true);
    try {
      await signOut(auth);
      setIsAdmin(false);
      localStorage.removeItem("isAdmin"); // 관리자 상태 제거
    } catch (error) {
      console.error("로그아웃 실패:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminContext.Provider value={{ isAdmin, loading, login, logout }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
