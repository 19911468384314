import Screen61 from "../assets/screen-6-1.png";
import Screen62 from "../assets/screen-6-2.png";
import Arrow from "../assets/arrow.svg";
import "../styles/page-box.css";
import React, {useState} from "react";
import {motion} from "framer-motion";

const PageBox: React.FC = () => {
  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState(1);

  const variants = {
    enter: (dir: number) => ({
      x: dir > 0 ? "100%" : "-100%",
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (dir: number) => ({
      x: dir > 0 ? "-100%" : "100%",
      opacity: 0,
    }),
  };

  const paginate = (newPage: number) => {
    setDirection(newPage > page ? 1 : -1);
    setPage(newPage);
  };

  return (
    <div className="page-box" style={{position: "relative", overflow: "hidden"}}>
      <img className={'arrow'}
           style={{transform: 'rotate(180deg)', visibility: page === 0 ? 'hidden' : 'visible'}}
           src={Arrow} alt={''}
           onClick={() => {
             paginate(0)
           }}/>
      <motion.div
        key={page}
        className="six-content"
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{duration: 0.5}}
        style={{width: "100%"}}
      >
        <div className={'six-content'}>
          <img className="six-content-img" src={page === 0 ? Screen61 : Screen62} alt=""/>
          <div className="six-content-text">
            <div className="text-32" style={{textAlign: "start"}}>
              {page === 0 ? "나만의 플래너 찾기" : "피드백을 통한 꼼꼼한 맞춤 매칭"}
            </div>
            {page === 0 ? (
              <div className="text-20 opacity-60" style={{textAlign: "start", lineHeight: '1.6'}}>
                나에게 가장 잘 맞는 플래너 찾기<br/>편안한 상담을 통해 <br/>솔직하게 모두 요구 해보세요
              </div>
            ) : (
              <div className={'text-frame'}>
                <div className="text-box left">
                  <div className={'text-content'}>이 분도 너무 괜찮으시지만, 서울에 거주하시는 분 더 보고싶어요.</div>
                </div>
                <div className="text-box right">
                  <div className={'text-content'}>검증 두개 이상 받으신 분이 좋아요.</div>
                </div>
                <div className="text-box left">
                  <div className={'text-content'}>가장 빠른 평일 저녁 데이트 스케줄 여쭤봐주세요.</div>
                </div>
                <div className="text-box right">
                  <div className={'text-content'}>취미가 같았으면 좋겠습니다.</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </motion.div>
      <img className={'arrow'}
           style={{visibility: page === 1 ? 'hidden' : 'visible'}}
           src={Arrow} alt={''}
           onClick={() => {
             paginate(1)
           }}/>
    </div>
  )
    ;
};

export default PageBox;
