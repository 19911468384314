import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { getStorage } from "firebase/storage"; // ✅ Storage 가져오기

const firebaseConfig = {
  apiKey: "AIzaSyA3uOLUqVUTW7ywEvxZb_z9dXvr6WCTo-o",
  authDomain: "another-class.firebaseapp.com",
  projectId: "another-class",
  storageBucket: "another-class.firebasestorage.app", // ✅ storageBucket 올바르게 수정
  messagingSenderId: "968215227644",
  appId: "1:968215227644:web:160152c01e54139229bb79",
  measurementId: "G-BPVMD988BB"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // ✅ Firebase Storage 초기화

export { auth, db, storage, GoogleAuthProvider, signInWithPopup, signOut };
